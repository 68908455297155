// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=VT323);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo {\n  text-align: left;\n  font-family: \"Press Start 2P\", \"VT323\";\n  color: black;\n}\n\nhtml, body{\n  height:100%;\n  position:fixed;\n  background-color: #ffffff;\n  \n}\nbody{\n  overflow:hidden;\n}\n\n.markdown img {\n  width: 300px;\n  height: 200px;\n}\n\n/* #E3E5E7 */\n\n\n\n\n\n/* \n.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{ font-size: 25px !important;}\n\n\n.ant-menu-inline > .ant-menu-item{ font-size: 25px !important;} */\n\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAIA;EACE,gBAAgB;EAChB,sCAAsC;EACtC,YAAY;AACd;;AAEA;EACE,WAAW;EACX,cAAc;EACd,yBAAyB;;AAE3B;AACA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA,YAAY;;;;;;AAMZ;;;;iEAIiE","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Press+Start+2P\");\n@import url(\"https://fonts.googleapis.com/css?family=VT323\");\n\n\n.logo {\n  text-align: left;\n  font-family: \"Press Start 2P\", \"VT323\";\n  color: black;\n}\n\nhtml, body{\n  height:100%;\n  position:fixed;\n  background-color: #ffffff;\n  \n}\nbody{\n  overflow:hidden;\n}\n\n.markdown img {\n  width: 300px;\n  height: 200px;\n}\n\n/* #E3E5E7 */\n\n\n\n\n\n/* \n.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{ font-size: 25px !important;}\n\n\n.ant-menu-inline > .ant-menu-item{ font-size: 25px !important;} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
