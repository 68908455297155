import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import copy from "copy-to-clipboard";
import {domain} from "./env"
import "./App.css";
import { Divider, Typography,Input as Input_antd } from "antd";
import {
  Space,
  Upload,
  Popover,
  notification,
  Tooltip,
  Avatar,
  Modal,
} from "antd";
import { Button } from "antd";
import { message, 
  // Drawer, Dropdown 
} from "antd";
import Typewriter from "typewriter-effect";
import { Tranquiluxe } from "uvcanvas"
import ImageComponent from "./component/ImageComponent";
import {
  formatDate,
  formatHourAndMinute,
  formatDate_tellDay,
  formatDate_tellDate,
  ifPressCommandAndEnter,
} from "./tool";
import {
  InfoCircleOutlined,
  QuestionOutlined,
  LoadingOutlined,
  SettingOutlined,
  UserOutlined,
  LockOutlined,
  CloseOutlined,
  AppstoreOutlined,
  CopyOutlined,
  CommentOutlined,
  MenuOutlined,
  MailOutlined
} from "@ant-design/icons";
import Compo_card_groupchatInfo from "./component/GroupchatInfo";
import gpticon from "./static/gpticon.png";
import logo_agentplus from "./static/logo_agentplus.png";
import Page_dashboard from "./page/Dashboard";
import AboutUs from "./page/AboutUs";
import Embedding from "./page/Embedding";
import Page_ResetPassword from "./page/ResetPassword";
import { TextArea, Popup, Input, Menu } from "antd-mobile";
// import { Input as inputAntd } from "antd";
import ReactMarkdown from "react-markdown";
import { env_serverAddress,version,InfoPoweredBy,InfoDescription,ifShowContactus} from "./env";
// import Compo_roomSetting from "./component/RoomSetting";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Compo_card_system_info_message from "./component/SystemInfoMessage";
import { loginUser, onLogin, tellUserToken, removeToken,ifHasToken } from "./auth";
import ChatContent from "./component/ChatContent";




//---------------------------------------------
// var version = " 1.0.1";
// const InfoPoweredBy = "Powered by GPT,Gemini,Llama"
// const InfoDescription = "Our project leverages state-of-the-art large language models to provide enhanced services while prioritizing user privacy and security. By harnessing cutting-edge advancements in various prominent language models, we ensure that users receive superior service without compromising their privacy or security."
//---------------------------------------------



const changeFavicon = (faviconURL) => {
  const link = document.querySelector("link[rel~='icon']");
  if (link) {
    link.href = faviconURL;
  } else {
    const newLink = document.createElement('link');
    newLink.rel = 'icon';
    newLink.href = faviconURL;
    document.head.appendChild(newLink);
  }
};




window.global = {
  // your global variables here
  ifGotResponse_fetchMessageHistory: true,
  ifGotResponse_fetchMessageHistory: true,
};


class App extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myRef_emial_login = React.createRef();
    this.myRef_password_login = React.createRef();
    this.myRef_emial_register = React.createRef();
    this.myRef_vcode_register = React.createRef();
    this.myRef_password_1_register = React.createRef();
    this.myRef_password_2_register = React.createRef();
    // this.consoleEndRef = React.createRef();
  }

  //pinS
  state = {
    resetPasswordTargetEmail:"",
    lockWelcomePageButton:false,
    gptToken:0,
    InputingPasswordOfRoom:"",
    ifCheckingPasswordOfRoom:false,
    ifAlreadyGotLastFetch: true,
    ifAllowLogin: false,
    ifLogined: false,
    setVcode: 0,
    ifOwner: false,
    ifIsAddingNewUser: false,
    ifFetchingData: false,
    ifFetchingPublicData: false,
    ifIsSendingEmail: false,
    ifIsLoggingIn: false,
    ifShowRoomSetting: false,
    ifProcessing: false,
    ifShowLogin: false,
    //pin2-------------
    ifShowWelcome: true,
    ifShowDashboard: false,
    ifShowApplyAsGuest: false,
    ifBeenRefrused: false,
    ifShowLeftBar: false,
    ifCloseFetchingData: false,
    ifNeedToFetchUserData: true,
    ifFetchingUserData: false,
    history: [],
    memberList: [],
    roomid:0,
    current_roombanner: null,
    current_gptid: 0,
    current_gptName: "crabGPT",
    lastTimeFetchedChatHistory: 0,
    // token_gpt: 0,
    gptData:{}
  };


  // pinDeleteMessage
  sendResetPasswordEmail = async () => {

    this.setState({ifSendingResetPasswordEmail:true})
    if(this.state.resetPasswordTargetEmail != ""){
      console.log(this.state.resetPasswordTargetEmail)
      try {
        let response = await fetch(env_serverAddress + "back/mygpt/sendresetpasswordemail", {
          method: "post",body: JSON.stringify({
            email: this.state.resetPasswordTargetEmail,
          }), headers: {"Content-Type": "application/json"}
        })
        let data = await response.json()
        console.log(data)
        if(data){
          if(data.statusCode == 1){
            message.success("email sent!")
          }
          if(data.statusCode == 4){
            message.info("please try again after 60s")
          }
          if(data.statusCode == 5){
            message.info("no such user👀")
          }
        }
      } catch (error) {
        message.error("client error")
        console.log(error)
      }
    }else{
      message.error("please input your email first")
    }
    this.setState({ifSendingResetPasswordEmail:false})
    
    
  };


  // pinDeleteMessage
  deleteMessage = async () => {

    this.setState({lastTimeFetchedChatHistory:0})
    if(tellUserToken()){
      try {
        var url = env_serverAddress + "back/mygpt/delmessage";
        let response = await fetch(url, {
          method: "post",
          body: JSON.stringify({
            gptToken: this.state.gptToken,
            userToken:tellUserToken()
          }), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
          headers: {
            "Content-Type": "application/json",
          },
        })
        let data = await response.json()
        if(data){
          this.fetchMessageHistory()
        }
      } catch (error) {
        console.log(error)
      }
    }else{
      this.setState({ 
        history:[{ role: "assistant", index: 1, content: this.state.gptData.gptHello }]
      })
    }
    
    
  };
  
  // pinFetchPublic
  fetchPublicData = async (gptToken,userToken) => {
    // console.log(userToken)
    this.setState({ifFetchingPublicData:true})
    if (gptToken != 0) {
      try {
        var url = env_serverAddress + "back/mygpt/fetchpublicdata";
        let response = await fetch(url, {
          method: "post",
          body: JSON.stringify({
            gptToken: gptToken,
            userToken:userToken
          }), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
          headers: {
            "Content-Type": "application/json",
          },
        })
        let data = await response.json()
        console.log(data);
        return(data)
      } catch (error) {
        console.log(error)
        return(0)
      } finally{
        this.setState({ifFetchingPublicData:false})
      }
    }
    
  };

  
  //pinFetchMessage
  fetchMessageHistory = async () => {
    // 仅仅面向普通login后的用户

    var gptToken = this.state.gptToken
    var userToken = tellUserToken()
    var lastTimeFetchedChatHistory = this.state.lastTimeFetchedChatHistory;

    try {
      let response = await fetch(env_serverAddress + "back/mygpt/fetchchathistory", {method: "post",body: JSON.stringify({
        userToken: userToken,
        lastTimeFetchedChatHistory: lastTimeFetchedChatHistory,
        gptToken: gptToken,
      }),headers: {"Content-Type": "application/json"}})

      let data = await response.json();
      console.log(data);
      if(data){

        if(data.statusCode == 8){
          this.logOut()
        }
        if(data.statusCode == 1){
          this.setState({lastTimeFetchedChatHistory: data.returnData.lastTimeUpdated})
          this.setState({ history: data.returnData.messageHis });
        }

      }else{
        // this.setState({ ifBeenRefrused: true });
        message.error("Server Error 😥")
      }
    }catch(error){}
  }

  //pinFetchUser
  fetchUserBasicData = async () => {
    this.setState({ ifFetchingUserData: true });
    var url = env_serverAddress + "back/mygpt/fetchuserbasicdata"; //传值的地址
    let response = await fetch(url, {
      method: "post",
      body: JSON.stringify({
        token: tellUserToken(),
      }), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    console.log(result);

    if(result.statusCode != 0){
      this.setState({
        ifLogined: true,
        userData:result.returnData,
        ifFetchingUserData: false
      });
    }
    if(result.statusCode == 0){
      this.logOut()
    }
    
  };

  //pinPost
  postMessage_callGpt = async (content) => {
    console.log("postMessage_callGpt--------------")
    var history = this.state.history;
    history.push({
      role: "user",
      time: Math.floor(Date.now() / 1000),
      content:content,
    });
    this.setState({ history: history });
   
    var url = env_serverAddress + "back/mygpt/postmessage_requiregpt"; //传值的地址
    let response = await fetch(url, {
      method: "post",
      body: JSON.stringify({
        content: content,
        userToken: tellUserToken(),
        gptToken: this.state.gptToken,
        history:history, //仅仅用于游客
        password:this.state.InputingPasswordOfRoom
      }), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    console.log(result);
    if (result.statusCode == 0) {
      message.error(this.state.current_gptName + "は現在、完全沈黙！");
    }
    if(result.statusCode == 1 || result.statusCode == 2){
      //如果是游客
      if(tellUserToken() == 0){
        this.setState({ history: result.returnData });  
      }else{
        this.fetchMessageHistory()
      }
    }
    if(result.statusCode == 8){
      message.error("権限はありません💦");  
    }
    
  };

  logOut = () => {
    //console.log("haha")
    removeToken()
    this.setState({
      ifLogined: false,
      ifAllowLogin: true,
      ifShowRoomSetting: false,
      ifShowDashboard:false,
      // ifBeenRefrused: true,
      ifShowWelcome: true,
      ifOwner: false,
      history: [],
      time_lastUpdated: 0,
      ifNeedToFetchUserData: true,
      lastTimeFetchedChatHistory:0,
    });

    this.runApp(this.state.gptToken)
    // clearInterval(this.interval);
    

  };


  addNewUser = async () => {
    this.setState({ ifIsAddingNewUser: true });
    var email = this.state.email;
    var password = this.state.setPassword_1;

    var ifNotOkToCreatePassword = true;
    if (this.state.setPassword_1 != undefined) {
      if (this.state.setPassword_2 != undefined) {
        if (this.state.setPassword_1 == this.state.setPassword_2) {
          if (this.state.setPassword_1.length >= 6) {
            ifNotOkToCreatePassword = false;
          }
        }
      }
    }

    if (ifNotOkToCreatePassword) {
      message.error(
        "Please ensure that the password exceeds 6 digits and the two entries are consistent 😥"
      );
      this.setState({ ifIsAddingNewUser: false });
    } else {
      var vcode = this.state.setVcode;
      var url = env_serverAddress + "back/newuser";
      let response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          email: email,
          vcode: vcode,
          password: password,
        }), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
        headers: {
          "Content-Type": "application/json",
        },
      });
      let temp = await response.json().then();

      if (temp != 1) {
        // 2:user exsists  3:please wait  4:vcode wrong!
        if (temp == 0) {
          message.error("Please send the verification code first 😥");
        }
        if (temp == 2) {
          message.error("The email seems to have been used 😥");
        }
        if (temp == 3) {
          message.error("The server is busy, please try again 😥");
        }
        if (temp == 4) {
          message.error("Verification code error 😥");
        }
        this.setState({ ifIsAddingNewUser: false });
      } else {
        this.login();
        message.success("done! 👍");
        this.setState({ ifIsAddingNewUser: false });
        this.close_ifShowLogin();
      }
    }
  };

  sendVcode = async () => {
    this.setState({ ifIsSendingEmail: true });
    if (this.state.email == undefined) {
      message.error("Please fill in the email first 😥");
    } else if (this.state.email.length < 5) {
      message.error("Please fill in the email first 😥");
    } else {
      var email = this.state.email;
      var url = env_serverAddress + "back/sendvcode";
      let response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          email: email,
        }), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
        headers: {
          "Content-Type": "application/json",
        },
      });
      let temp = await response.json().then();
      console.log(temp)
      if(temp == 0){
        message.error("please check your email again😥")
      }
      if (temp != 1) {
        if (temp == 4) {
          message.error(
            "Your operation is too fast. Please try again in 30 seconds 😥"
          );
        }
      } else {
        message.success("The verification code has been sent! ✉️");
      }
      this.setState({ ifIsSendingEmail: false });
    }
    this.setState({ ifIsSendingEmail: false });
  };

  recieveEmail = (value) => {
    this.setState({ email: value });
  };
  recievePassword = (value) => {
    //console.log(value.target.value)
    this.setState({ password: value });
  };
  recievePassword_2 = (value) => {
    //console.log(value.target.value)
    this.setState({ password_2_register: value });
  };

  checkData() {
    // 如果是登陆后的用户的话,定时自动获取最新消息
    if (!this.state.ifShowWelcome && tellUserToken() && this.state.gptToken != 0) {
      this.fetchMessageHistory();
      //console.log("haha")
    }
  }

  //pinLogin
  login = async () => {
    this.setState({ifIsLoggingIn: true});
    try{
      let response = await fetch(env_serverAddress + "back/login", {
        method: "post",
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.setPassword_1,
        }), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
        headers: {
          "Content-Type": "application/json",
        },
      })
      let token_here = await response.json().then();
      if (token_here != 0) {
        this.setState({ifShowLogin: false,ifBeenRefrused: false});
        onLogin(token_here);
        this.myRef_emial_login.current.clear()
        this.myRef_password_login.current.clear()
        // this.fetchMessageHistory();
        console.log()
        setTimeout(() => {
          this.runApp(this.state.gptToken)  
        }, 300)
        
        
        // this.setState({ ifIsLoggingIn: false, ifLogined: true,ifBeenRefrused: false });
      } else {
        message.error("Password error 😥");
      }
    }catch(error){
      console.log(error)
      message.info("Server under maintenance")
    }finally{
      this.setState({ ifIsLoggingIn: false });
    }
  }

  // pinRun
  runApp = async(gptToken) =>{
    //-----------------------------------------------------
    // 通过gpt的token,向后台获取gpt的公开数据    
    const userToken = tellUserToken()

    if(gptToken != 0){
      // 第一遍运行的时候储存起来gptToken
      this.setState({gptToken:gptToken})
      const publicData = await this.fetchPublicData(gptToken,userToken);
      if(publicData){
        this.setState({gptData:publicData.returnData})
        // 如果是owner或者gpt不需要密码的话
        if(publicData.returnData.ifOwner == 1 || publicData.returnData.ifPassword == 0){
          
          // 设置页面标题
          document.title = publicData.returnData.gptName +" by Agent+" 
          // 设置Favicon
          if(publicData.returnData.gptIcon != 0){
            changeFavicon(publicData.returnData.gptIcon);
          }
          

          // 关闭welcome页面
          this.setState({lockWelcomePageButton:true})
          setTimeout(() => {
            this.setState({ 
              ifShowWelcome: false, ifShowDashboard: false,
              lockWelcomePageButton:false})}, 2000)
        }
      }else{
        this.setState({gptData:0})
      }
    }

    // 如果是登录用户
    if(userToken){
      await this.fetchUserBasicData()
      if(gptToken != 0){this.fetchMessageHistory()}
    }else{
      console.log("no logined")
      // 如果是未登陆的用户
      if(gptToken != 0){
        // 未登录用户直接显示主界面,并初始化初期数据
        setTimeout(() => {this.setState({ 
          history:[{ role: "assistant", index: 1, content: this.state.gptData.gptHello }]
        })}, 2000)
      }
      
      // 未登录用户不自动抓取后台信息
    }
  }
  

  // pinDid
  componentDidMount() {
    console.clear();
    window.addEventListener("resize", this.resize);
    //-----------------------------------------------------
    // 监视gptid,获取当前网页的gpt的token
    // var s2 = 0
    var page = 0
    var tokenInUrl = 0
    try {
      var search_here = this.props.location.search;
      page = search_here.split("?")[1].split("=")[0];
      tokenInUrl = search_here.split("?")[1].split("=")[1];

      // this.setState({ token_gpt: tokenInUrl });
    } catch (error) {
      // this.setState({ token_gpt: 0 });
    }
    // -----------------------------------------------------
    // 如果是普通的agent页面,此处的id为gptToken
    if(page == "id"){
      this.runApp(tokenInUrl)
    }
    else if(page == "resetpassword"){
      this.setState({
        ifShowResetpassword:true,
        resetPasswordToken:tokenInUrl,
      })
      // this.runApp(tokenInUrl)
      
    }else if(page == "embedding"){
      this.setState({
        ifShowEmbedding:true
      })
    }
    
    else{
      const userToken = tellUserToken()
      if(userToken){
        this.fetchUserBasicData()
      }
    }
    

    if(tokenInUrl != 0 && page == "id"){
      // 自动获取最新数据
      this.interval = setInterval(() => {
        this.checkData();
      }, 3000)
    }
  }


  resize = () => {
    var height = document.documentElement.clientHeight;
    var width = document.documentElement.clientWidth;
    this.setState({
      height: height,
      width: width,
    });
    // this.scrollToBottom();
  }

  



  componentDidUpdate(prevProps, prevState) {
    if (!prevState.showDescription && this.state.showDescription) {
      const descriptionElement = document.querySelector(".description");
      descriptionElement.style.opacity = 1;
    }
  }


  checkPasswordOfRoom = async () => {
    this.setState({ifCheckingPasswordOfRoom:true})
    var url = env_serverAddress + "back/mygpt/checkpasswordofroom";
    let response = await fetch(url, {
      method: "post",
      body: JSON.stringify({
        password: this.state.InputingPasswordOfRoom,
        gptToken:this.state.gptToken
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    console.log(result)
    if(result){
      if(result.statusCode == 1){
        message.success("welcome!")
        this.setState({ifShowWelcome:false})

      }else{
        message.error("wrong password")
      }
    }
    this.setState({ifCheckingPasswordOfRoom:false})
  };


  // pinPass
  onChange_InputingPasswordOfRoom = (e) => {
    console.log(e.target.value)
    this.setState({InputingPasswordOfRoom:e.target.value})
  };
  open_ifShowDashboard = () => {this.setState({ ifShowDashboard: true, ifCloseFetchingData: true })};
  close_ifShowDashboard = () => {this.setState({ ifShowDashboard: false, ifCloseFetchingData: false });};
  open_ifShowLogin = () => {this.setState({ ifShowLogin: true,ifShowLeftBar: false});};
  close_ifShowLogin = () => {
    this.setState({ ifShowLogin: false });
    this.myRef_emial_login.current.clear();
    this.myRef_password_login.current.clear();
    this.myRef_emial_register.current.clear();
    this.myRef_vcode_register.current.clear();
    this.myRef_password_1_register.current.clear();
    this.myRef_password_2_register.current.clear();
  };
  createPassword_1 = (value) => {this.setState({setPassword_1: value,password: value,});};
  createPassword_2 = (value) => {this.setState({ setPassword_2: value });};
  createVcode = (value) => {this.setState({ setVcode: value });};

  render() {
    var width = document.documentElement.clientWidth;
    var height = document.documentElement.clientHeight;
    var width_leftBar = 200; //200

    var ifMobile = width < height;
    if (ifMobile) {
      width_leftBar = 0;
    }

    var width_middle = width - width_leftBar;

    var banner_here = this.state.gptData.gptBanner;
    if (banner_here == 0 || banner_here == "" || banner_here == "0" || banner_here== undefined) {
      banner_here = 0;
    }
    if (banner_here == null && this.state.gptToken == 0) {
      banner_here = 0;
    }

    // pinReturn
    if(this.state.ifShowEmbedding){
      return(
        <div><Embedding/></div>
      )
    }
    // pinReturn
    if(this.state.ifFetchingPublicData){
      return (
        <div
          style={{
            width: width,
            height: height,
          }}
          onResize={this.onResize}
        >
          <LoadingOutlined style={{fontSize:30,position:"absolute",right:10,top:10}}/>
        </div>
      )
    }
    else{
      return (
        <div
          style={{
            width: width,
            height: height,
            position: "fixed",
            left: 0,
            top: 0,
          }}
          onResize={this.onResize}
        >
          {/* 状态栏目 */}
          <div
            style={{
              width: width,
              backgroundColor: "#ffffff",
              height: 40,
              position: "absolute",
              top: 0,
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              zIndex:999,
            }}
            >
            {ifMobile && (
              <div
              style={{
                position: "absolute",
                left: 15,
                top: 10,
                width: 30,
                cursor: "pointer",
              }}
            >
              <MenuOutlined
                style={{ fontSize: 20 }}
                onClick={() => {
                  this.setState({ ifShowLeftBar: true });
                }}
              />
            </div>
            )}
  
            <Space>
							<div style={{ 
								width: 200, height: 40, 
								// backgroundColor: "#ff0000" ,
								// width: 90,
								marginLeft: width / 2 - 45,
								marginTop: 5,
								}}>
								<a  href={domain} target="_blank" rel="noopener noreferrer">
									<img
										style={{
											width: 90,
										}}
										src={logo_agentplus}
									/>
								</a>
							</div>
						</Space>
            


            <div
              style={{
                position: "absolute",
                right: 10,
                top: 5,
                // width: 30,
                cursor: "pointer",
              }}
            >
              <Space>
                {ifShowContactus==1 && (
                  <Button
                    type="link"
                    style={{
                      // backgroundColor: "#d8d8d8",
                      // width: 30,
                      marginTop:-1,
                      height: 30,
                    }}
                    icon={<CommentOutlined />}
                    onClick={()=>{
                      this.setState({ifShowAboutUs:true})
                    }}
                    // shape="circle"
                  />
                )}
                
                  
                {/* <div style={{width:-5}}/> */}

                {this.state.ifLogined ? (
                  <Avatar
                    style={{
                      backgroundColor: "#1777FF",
                      width: 30,
                      height: 30,
                    }}
                    icon={<UserOutlined style={{ fontSize: 18 }} />}
                    onClick={this.open_ifShowDashboard}
                  />
                ):(
                  <Avatar
                      style={{
                        backgroundColor: "#d8d8d8",
                        width: 30,
                        height: 30,
                      }}
                      icon={<UserOutlined style={{ fontSize: 18 }} />}
                      onClick={()=>{
                        this.setState({ifShowLogin:true})
                      }}
                    />
                )}
                
              </Space>
            </div>

            
  
            <div
              style={{ width: width, height: 1, position: "absolute", top: 16 }}
            >
              <Divider />
            </div>
          </div>
  
          {ifMobile ? (
            ""
          ) : (
            <div
              style={{
                width: width_leftBar - 40,
                backgroundColor: "#f1f1f1",
                height: height,
                float: "left",
                padding: 20,
                paddingTop: 55,
                WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none'
              }}
            >
                <div style={{ height: 0 }} />
  
                <Compo_card_groupchatInfo
                  title={this.state.gptData.gptName}
                  current_roombanner={this.state.gptData.gptBanner}
                  description={this.state.gptData.gptDescription}
                />
  
    
                <div style={{ height: 10 }} />
  
                
                <div
                  style={{
                    position: "fixed",
                    backgroundColor: "#f1f1f1",
                    bottom: 20,
                    height: 20,
                    width: 170,
                    marginLeft: 0,
                    paddingLeft: 0,
                    paddingTop: 20,
                    paddingBottom: 20,
                  }}
                >
                  {/* {!this.state.ifLogined && (
                    <Button
                      onClick={this.open_ifShowLogin}
                      size="large"
                      type="primary"
                      style={{ width: 180,marginLeft:-10 }}
                      icon={<UserOutlined />}
                    >
                      Login
                    </Button>
                  )} */}
                    
                  
                </div>
              </div>
           
          )}
  
          <div style={{position:"absolute",top:0,left:width_leftBar}}>
            <ChatContent
              history = {this.state.history}
              gptData = {this.state.gptData}
              postMessage_callGpt={this.postMessage_callGpt}
              showLogin={()=>{this.setState({ifShowLogin:true})}}
              deleteMessage = {this.deleteMessage}
              ifEmbedding = {false}
            />  

          </div>
          
         
  
          {/*  pinPro desktop RoomSetting */}
          {/* <Modal
            onOk={() => {
              this.close_ifShowRoomSetting();
            }}
            onCancel={() => {
              this.close_ifShowRoomSetting();
            }}
            open={this.state.ifShowRoomSetting}
            //bodyStyle={{height: height,backgroundColor:"#ffffff"}}
  
            //title="Select you GPT"
            centered
            width={1090}
            okText={"done"}
            footer={null}
          >
            <Compo_roomSetting
              title={this.state.current_roomname}
              banner={this.state.current_roombanner}
              //description={this.props.user_description}
              description={this.state.current_roomdescription}
              env_serverAddress={env_serverAddress}
              user_name={this.state.user_name}
              user_token={tellUserToken()}
              ifOwner={this.state.ifOwner}
              token_gpt={this.state.token_gpt}
              logOut={this.logOut}
              guestLeave_commit={this.guestLeave_commit}
            />
  
          </Modal> */}
  
          {/* pinDash dashboard */}
          <Popup
            position="bottom"
            onClose={() => {
              this.close_ifShowDashboard();
            }}
            showCloseButton={this.state.gptToken != 0}
            visible={this.state.ifShowDashboard}
            bodyStyle={{
              height: height,
              width: width,
            }}
          >
            <div>
              <Page_dashboard
                callBack_closePage = {this.close_ifShowDashboard}
                logOut={this.logOut}
                userData = {this.state.userData}
              />
            </div>
          </Popup>
          
          
  
          {/* pinWel 欢迎界面 */}
          <Popup
            position="bottom"
            onClose={() => {
              this.setState({ ifShowWelcome: false });
            }}
            //showCloseButton
            visible={this.state.ifShowWelcome}
            bodyStyle={{
              height: height,
              width: width,
            }}
          >
            <div>
              {!ifMobile && (
                <div
                  style={{
                    height: height,
                    overflowX: "hidden",
                    overflowY: "auto",
                    width: width / 2,
                    float: "left",
                    overflow: "hidden",
                    //backgroundColor:"#E25624"
                  }}
                >
                  {banner_here == 0 ? (
                    <Tranquiluxe/>
                  ):(
                    <ImageComponent
                      src={banner_here}
                      width={width / 2}
                      height={height}
                    />
                    )}
                  
  
                  {/* <img src={banner_here} style={{width:width/2}}/> */}
                </div>
              )}
  
              {/* 右边登陆窗口 */}
              {/* pin1 */}
              {/* ---------------------------------------- */}
              <div
                style={{
                  height: height,
                  overflowX: "hidden",
                  overflowY: "auto",
                  width: ifMobile ? width - 10 : width / 2 - 10,
                  paddingLeft: 20,
                  paddingTop: 10,
                  float: "left",
                  // backgroundColor:"#d8d8d8",
                  position: "fixed",
                  right: 0,
                  top: 0,
                }}
              >
                {ifMobile && (
                  <div
                    style={{
                      height: height / 2,
                      overflowX: "hidden",
                      overflowY: "auto",
                      width: width,
                      float: "left",
                      overflow: "hidden",
                      marginLeft: -10,
                      marginTop: -10,
                      //backgroundColor:"#E25624"
                    }}
                  >
                    {banner_here == 0 ? (
                      <Tranquiluxe/>
                    ) : (
                      <ImageComponent
                        src={banner_here}
                        width={width}
                        height={height / 2}
                      />
                      
                    )}
  
                    {/* <img src={banner_here} style={{width:width/2}}/> */}
                  </div>
                )}
                <div
                  style={{
                    position: "absolute",
                    left: 30,
                    top: ifMobile ? height / 2 : 30,
                  }}
                >
                  <div style={{ color: "#d8d8d8" }}>
                    {/* <div style={{height:0}}/> */}
                    {!ifMobile && (
                      <div>
                        <div
                          style={{
                            paddingLeft: 0,
                            paddingRight: 20,
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {InfoPoweredBy}
                        </div>
                        <div style={{ height: 10 }} />
                        <div
                          style={{
                            paddingLeft: 0,
                            paddingRight: 20,
                            fontSize: "10px",
                            width:width/2-40
                          }}
                        >
                         {InfoDescription}
                        </div>
                        
                        <div style={{ width: width / 2 - 60 }}>
                          <Divider />
                        </div>
                      </div>
                    )}
  
                    <div style={{ height: 5 }} />
                    {ifMobile && <div style={{ height: 10 }} />}
                    {/* pin2 */}
                    {this.state.gptToken == 0 ? (
                      // 如果roomid为0,显示dashboard
                      this.state.ifLogined ? (
                        <div>
                          {this.state.ifFetchingUserData ? (
                            <div>
                              <div
                                style={{
                                  color: "#000000",
                                  paddingLeft: 0,
                                  paddingRight: 20,
                                  fontSize: "40px",
                                  fontWeight: "bold",
                                }}
                              >
                                Accessing 🤖
                              </div>
                              <div
                                style={{
                                  color: "#000000",
                                  paddingLeft: 0,
                                  paddingRight: 20,
                                  fontSize: "40px",
                                  fontWeight: "bold",
                                }}
                              >
                                <LoadingOutlined />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div
                                style={{
                                  color: "#000000",
                                  paddingLeft: 0,
                                  paddingRight: 20,
                                  fontSize: "40px",
                                  fontWeight: "bold",
                                }}
                              >
                                Welcome back!
                              </div>
                              <div
                                style={{
                                  color: "#000000",
                                  paddingLeft: 0,
                                  paddingRight: 20,
                                  fontSize: "40px",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.state.userData?.username}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{
                              color: "#000000",
                              paddingLeft: 0,
                              paddingRight: 20,
                              fontSize: ifMobile? 40:70,
                              fontWeight: "bold",
                              width: ifMobile? width-40:width/2
                            }}
                          >
                            {/* Personalize, Collaborate, Explore */}
                            <Typewriter
                              onInit={(typewriter) => {
                                typewriter
                                  .typeString("Personalize")
                                  .pauseFor(500)
                                  .deleteAll()
                                  .typeString("Share")
                                  .pauseFor(500)
                                  .deleteAll()
                                  .typeString("Explore")
                                  .pauseFor(500)
                                  .deleteAll()
                                  .typeString("<strong>agent+</strong>")
                                  .callFunction(() => {
                                    this.setState({ showDescription: true });
                                  })
                                  .start();
                              }}
                            />
                            <div style={{ height: 30 }} />
                            <div
                              className="description"
                              style={{
                                opacity: 0,
                                fontSize: "14px",
                                fontWeight: "normal",
                                transition: "opacity 3s",
                                paddingRight: 100,
                                maxWidth:500
                              }}
                            >
                              The platform where you can personalize your very own
                              AI Agents to suit your unique needs, share your
                              creations with other users, and explore the exciting
                              world of group chats with countless AI Agents.
                            </div>
                          </div>
                          <div style={{ height: 5 }} />
                        </div>
                      )
                    ) : 
                    // 如果是特定gpt的首页
                    this.state.gptData == 0 ? 
                      // 如果访问被拒绝
                      (
                        <div>
                          <div
                            style={{
                              color: "#000000",
                              paddingLeft: 0,
                              paddingRight: 20,
                              fontSize: "21px",
                            }}
                          >
                            404 Error Detected!!
                          </div>
                          <div style={{ height: 5 }} />
    
                          <div
                            style={{
                              color: "#000000",
                              paddingLeft: 0,
                              paddingRight: 20,
                              fontSize: "40px",
                              fontWeight: "bold",
                            }}
                          >
                            Ops..No such page 🤖
                          </div>
                        </div>

                      // 如果访问没有被拒绝
                      ) : (
                        //如果 需要密码
                        this.state.gptData.ifPassword ? (
                          <div>
                            <div
                              style={{
                                color: "#000000",
                                paddingLeft: 0,
                                paddingRight: 20,
                                fontSize: "21px",
                              }}
                            >
                              ㊙️　Locked agent!
                            </div>
                            <div style={{ height: 5 }} />
      
                            <div
                              style={{
                                color: "#000000",
                                paddingLeft: 0,
                                paddingRight: 20,
                                fontSize: "40px",
                                fontWeight: "bold",
                              }}
                              >
                              Ops..Need password
                            </div>
                          </div>
                        ):(
                          //如果 不需要 密码
                          <div>
                            <div
                              style={{
                                color: "#000000",
                                paddingLeft: 0,
                                paddingRight: 20,
                                fontSize: "21px",
                              }}
                            >
                              {/* ⛵️destination code:  */}
                              {/* {this.state.gptData.gptName} */}
                              <LoadingOutlined />{" "}Connecting...
                            </div>
                            <div style={{ height: 5 }} />
      
                            <div
                              style={{
                                color: "#000000",
                                paddingLeft: 0,
                                paddingRight: 20,
                                fontSize: "40px",
                                fontWeight: "bold",
                              }}
                            >
                              
                              {this.state.gptData.gptName}
                            </div>
                            {/* <div
                              style={{
                                color: "#000000",
                                paddingLeft: 0,
                                paddingRight: 20,
                                fontSize: "40px",
                                fontWeight: "bold",
                              }}
                            >
                              <LoadingOutlined />
                            </div> */}
                          </div>
                        )
                      )
                    }
                  </div>
                </div>

                {/* -------------------------------------------- */}
                {/* pin3 */}
  
                <div
                  style={{
                    position: "absolute",
                    left: 30,
                    bottom: 20,
                    backgroundColor: "#ffffff",
                  }}
                >
                  {/* 没有token_gpt,也就是在主界面的情况 */}
                  {this.state.ifLogined && (
                    <div>
                      <div style={{ height: 20 }} />

                      <Button
                        type="primary"
                        onClick={() => {
                          this.setState({ ifShowWelcome: false });
                          this.open_ifShowDashboard();
                        }}
                        disabled={this.state.lockWelcomePageButton}
                        style={{ width:ifMobile? width-40:290, height: 40 }}
                        icon={<AppstoreOutlined />}
                      >
                        Dashboard
                      </Button>
                      
                    </div>
                    
                  )}
                  {this.state.gptData.ifPassword == 1 && (
                    <div>
                      <div style={{ height: 10 }} />
                      <Input_antd
                        style={{
                          width:ifMobile? width-40:290
                        }}
                        type="text"
                        maxLength={20}
                        // autoComplete="new-password"
                        value={this.state.InputingPasswordOfRoom}
                        onChange={this.onChange_InputingPasswordOfRoom}
                        placeholder="input password" />
                      <div style={{ height: 20 }} />
                      <Space direction= "vertical" >
                        <Button
                          onClick={this.checkPasswordOfRoom}
                          type="primary"
                          disabled={this.state.ifCheckingPasswordOfRoom}
                          icon={this.state.ifCheckingPasswordOfRoom && <LoadingOutlined/>}
                          style={{ 
                            width: ifMobile ? width - 40 : 290, 
                            height: 40 }}
                        >
                          Enter
                        </Button>
                        <div style={{ height: 10,marginTop:-10 }} ><Divider /></div>
                    
                      </Space>
                    
                    </div>
                  )}
                  
                  {this.state.ifLogined ?(
                    <Button
                      type="link"
                      onClick={this.logOut}
                      style={{ width: ifMobile ? width - 40 : 290, height: 40 }}
                      disabled={this.state.lockWelcomePageButton}
                    >
                      logout
                    </Button>
                  ):(
                    <div>             
                      <div style={{ height: 10 }} />
                      <Button
                        onClick={this.open_ifShowLogin}
                        disabled={this.state.lockWelcomePageButton}
                        type="primary"
                        // disabled={!this.state.ifBeenRefrused}
                        style={{ 
                          width: ifMobile ? width - 40 : 290, 
                          height: 40 }}
                      >
                        Login
                      </Button>
                    </div>
                  )}
  
                  <div style={{ height: 5 }} />
                  {!ifMobile && (
                    <div
                      style={{
                        width: width / 2 - 20,
                      }}
                    >
                      <Divider />
                    </div>
                  )}
  
                  <div style={{ color: "#d8d8d8" }}>
                    {!ifMobile && (
                      <div>
                        <div
                          style={{
                            paddingLeft: 0,
                            paddingRight: 20,
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          agent+{version}
                        </div>
                        <div style={{ height: 10 }} />
                        <div
                          style={{
                            paddingLeft: 0,
                            paddingRight: 20,
                            fontSize: "13px",
                          }}
                        >
                          mtplus 2023, all rights reserved
                        </div>
                      </div>
                    )}
                    <div style={{ height: 5 }} />
                  </div>
                </div>
              </div>
            </div>
          </Popup>



  
          {/* pinLogin 登陆界面 */}
          <Popup
            position={ifMobile? "bottom" : "right"}
            onMaskClick={() => {
              this.close_ifShowLogin();
            }}
            onClose={() => {
              this.close_ifShowLogin();
            }}
            showCloseButton
            visible={this.state.ifShowLogin}
            bodyStyle={{
              height: height,
              //backgroundColor:"#79D760"
              //borderTopLeftRadius: '8px',
              //borderTopRightRadius: '8px',
            }}
          >
            <div
              style={{
                height: height,
                overflowX: "hidden",
                overflowY: "auto",
                width: ifMobile ? width : 500,
                height:height,
              }}
            >
              <div
                style={{
                  height: "auto",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  //backgroundColor:"#79D760",
                  width: width,
                  height:height,
                }}
              >
                <div style={{ height: 30 }} />
                <div style={{ width: 440, paddingLeft: 30 }}>
                  <Divider />
                </div>
                <div
                  style={{
                    color: "#000000",
                    paddingLeft: 30,
                    fontSize: "40px",
                    fontWeight: "bold",
                  }}
                >
                  Login 🤖
                </div>
                <div style={{ height: 10 }} />
                <div
                  style={{ color: "#000000", paddingLeft: 30, fontSize: "20px" }}
                >
                  Start talking to AI agents with your members!
                </div>
                <div style={{ width: ifMobile? width-60:440, paddingLeft: 30 }}>
                  <Divider />
                </div>
                <div style={{ height: 40 }} />
                <div style={{ paddingLeft: 30 }}>
                  <div style={{ color: "#000000", fontSize: "20px" }}>email</div>
                  <div style={{ height: 10 }} />
                  <Input
                    style={{
                      height: "35px",
                      backgroundColor: "#f1f1f1",
                      width: ifMobile? width-60:440,
                    }}
                    onChange={this.recieveEmail}
                    ref={this.myRef_emial_login}
                    type="username"
                    //placeholder='请输入用户名'
                  />
                  <div style={{ height: 10 }} />
                  <div style={{ color: "#000000", fontSize: "20px" }}>
                    password
                  </div>
                  <div style={{ height: 10 }} />
                  <Input
                    style={{
                      height: "35px",
                      width: ifMobile? width-60:440,
                      backgroundColor: "#f1f1f1",
                    }}
                    onChange={this.createPassword_1}
                    ref={this.myRef_password_login}
                    type="password"
                    //placeholder='请输入用户名'
                  />
  
                  <div style={{ height: 20 }} />
                  <Button
                    icon={this.state.ifIsLoggingIn && <LoadingOutlined />}
                    type="primary"
                    onClick={this.login}
                    style={{ width: 140, height: 50 }}
                  >
                    Login
                  </Button>
                  <Button
                    
                    type="link"
                    onClick={()=>{
                      this.setState({ifShowFindPassword:true})
                    }}
                    style={{ width: 140, height: 50 }}
                  >
                    forgot password?
                  </Button>
                

                    
                </div>
  
                <div style={{ height: 80 }} />
                <div style={{ width: ifMobile? width-60:440, paddingLeft: 30 }}>
                  <Divider />
                </div>
                <div
                  style={{
                    color: "#000000",
                    paddingLeft: 30,
                    fontSize: "40px",
                    fontWeight: "bold",
                    width: ifMobile? width-60:440
                  }}
                >
                  No account? Register one!
                </div>
  
                <div style={{ height: 40 }} />
                <div style={{ paddingLeft: 30, borderColor: "#000000" }}>
                  <div style={{ color: "#000000", fontSize: "20px" }}>email</div>
                  <div style={{ height: 10 }} />
  
                  <Space>
                    <Input
                      style={{
                        height: "35px",
                        backgroundColor: "#f1f1f1",
                        width: ifMobile? width-179:260,
                      }}
                      onChange={this.recieveEmail}
                      ref={this.myRef_emial_register}
                      type="username"
                      //placeholder='请输入用户名'
                    />
  
                    <div style={{ width: 10 }} />
  
                    

                    <Button
                      icon = {this.state.ifIsSendingEmail && (<LoadingOutlined/>)}
                      onClick={this.sendVcode}
                      type="primary"
                      style={{ width: ifMobile? 100:160, height: 40 }}
                    >
                      verify
                    </Button>
                  </Space>
  
                  <div style={{ height: 20 }} />
                  <div style={{ color: "#000000", fontSize: "20px" }}>
                    Verification code
                  </div>
                  <div style={{ height: 10 }} />
                  <Input
                    style={{
                      height: "35px",
                      backgroundColor: "#f1f1f1",
                      width: 100,
                    }}
                    onChange={this.createVcode}
                    ref={this.myRef_vcode_register}
                    //type='username'
                    //placeholder='请输入用户名'
                  />
                  <div style={{ height: 20 }} />
  
                  <div style={{ color: "#000000", fontSize: "20px" }}>
                    password
                  </div>
                  <div style={{ height: 10 }} />
  
                  <Input
                    style={{
                      height: "35px",
                      backgroundColor: "#f1f1f1",
                      width: ifMobile? width-60:440,
                    }}
                    onChange={this.createPassword_1}
                    ref={this.myRef_password_1_register}
                    type="password"
                    //placeholder='请输入用户名'
                  />
  
                  <div style={{ height: 10 }} />
                  <div style={{ color: "#000000", fontSize: "20px" }}>
                    Please input again
                  </div>
                  <div style={{ height: 10 }} />
  
                  <Input
                    style={{
                      height: "35px",
                      backgroundColor: "#f1f1f1",
                      width: ifMobile? width-60:440,
                    }}
                    onChange={this.createPassword_2}
                    ref={this.myRef_password_2_register}
                    type="password"
                    //placeholder='请输入用户名'
                  />
                  <div style={{ height: 20 }} />
  
                  <div style={{ color: "#000000", fontSize: "15px" }}>
                    👀 Password must have at least 6 digits
                  </div>
                  <div style={{ height: 20 }} />
  
                  {this.state.ifIsAddingNewUser ? (
                    <Button
                      icon={<LoadingOutlined />}
                      disabled={true}
                      type="primary"
                      style={{ width: ifMobile? width-360:160, height: 50 }}
                    >
                      One moment please
                    </Button>
                  ) : (
                    <Button
                      onClick={this.addNewUser}
                      type="primary"
                      style={{ width: 160, height: 50 }}
                    >
                      Register and log in
                    </Button>
                  )}
                </div>
                <div style={{ height: 50 }} />
  
                <div style={{ width: ifMobile? width-60:440, paddingLeft: 30 }}>
                  <Divider />
                </div>
                <div
                  style={{ paddingLeft: 30, color: "#000000", fontSize: "10px" }}
                >
                  mt+
                </div>
                <div style={{ height: 20 }} />
              </div>
            </div>
          </Popup>
          

          {/* pinFindPassword */}
          <Popup
            position={ifMobile ? "bottom":"right"}
            onMaskClick={() => {
              this.setState({ ifShowFindPassword: false });
            }}
            onClose={() => {
              this.setState({ ifShowFindPassword: false });
            }}
            showCloseButton
            visible={this.state.ifShowFindPassword}
            bodyStyle={{
              height: ifMobile ? 300 : height,
              width: ifMobile ? width : 300,
            }}
          >
            <div
              style={{
                height: height,
                overflowX: "hidden",
                overflowY: "auto",
                height: ifMobile ? 300 : height,
                width: ifMobile ? width : 300,
              }}
            >
              <div
                style={{
                  // width: width_leftBar - 40,
                  // backgroundColor: "#f1f1f1",
                  height: height,
                  float: "left",
                  padding: 20,
                  // paddingTop: 55,
                }}
              >
                {/* <div style={{height:10}}/> */}
                <h2>Reset Password</h2>
                <Input_antd
                  placeholder='your email'
                  prefix={<MailOutlined />}
                  style={{
                    width:ifMobile? width-40:260
                  }}
                  maxLength={50}
                  value={this.state.resetPasswordTargetEmail}
                  onChange={(e)=>{
                    this.setState({resetPasswordTargetEmail:e.target.value})
                  }}
                  />
                <div style={{height:20}}/>
                <Button 
                  block 
                  icon={this.state.ifSendingResetPasswordEmail && (<LoadingOutlined/>)}
                  disabled={this.state.ifSendingResetPasswordEmail}
                  onClick ={()=>{this.sendResetPasswordEmail()}}
                  type="primary" 
                  style={{height:40}}>
                  Send reset link
                </Button>

  
                
  
            
                <div style={{ height: 10 }} />
  
                
                
              </div>
            </div>
          </Popup>
          
  
          
  
          
  
          
  
          {/* pinLeftBar */}
          <Popup
            position="left"
            onMaskClick={() => {
              this.setState({ ifShowLeftBar: false });
            }}
            onClose={() => {
              this.setState({ ifShowLeftBar: false });
            }}
            showCloseButton
            visible={this.state.ifShowLeftBar}
            bodyStyle={{
              height: height,
              width: 200,
            }}
          >
            <div
              style={{
                height: height,
                overflow: "hidden",
                // overflowY: "auto",
                width: 200,
              }}
            >
              <div
                style={{
                  // width: width_leftBar - 40,
                  backgroundColor: "#f1f1f1",
                  height: height,
                  float: "left",
                  padding: 20,
                  paddingTop: 55,
                }}
              >
                <div style={{ height: 0 }} />
  
                <Compo_card_groupchatInfo
                  title={this.state.gptData.gptName}
                  current_roombanner={this.state.gptData.gptBanner}
                  description={this.state.gptData.gptDescription}
                />
  
            
                <div style={{ height: 10 }} />
  
                
                
              </div>
            </div>
          </Popup>


          {/* pinResetPassword */}
          <Popup
            position={ifMobile? 'bottom':"right"}
            // onMaskClick={() => {
            //   this.setState({ ifShowResetpassword: false });
            // }}
            onClose={() => {
              this.setState({ ifShowResetpassword: false });
            }}
            // showCloseButton
            visible={this.state.ifShowResetpassword}
            bodyStyle={{
              height:ifMobile? height/2:height,
						  width:ifMobile? width:width/2
              // height: height,
              // width: width,
            }}
          >
            <div
              style={{
                height:ifMobile? height/2:height,
						    width:ifMobile? width:width/2,
                // height: height,
                overflow: "hidden",
                // overflowY: "auto",
                // width: width,
              }}
            >
              <div
                style={{
                  // width: width_leftBar - 40,
                  // backgroundColor: "#f1f1f1",
                  height: height,
                  float: "left",
                  padding: 20,
                  paddingTop: 55,
                }}
              >
                <div style={{ height: 0 }} />
  
                <Page_ResetPassword
                  resetPasswordToken = {this.state.resetPasswordToken}
                />

            
                <div style={{ height: 10 }} />
  
                
                
              </div>
            </div>
          </Popup>



          {/* pinAboutUs */}
          <Popup
            position={ifMobile? 'bottom':"right"}
            onMaskClick={() => {
              this.setState({ ifShowAboutUs: false });
            }}
            onClose={() => {
              this.setState({ ifShowAboutUs: false });
            }}
            // showCloseButton
            visible={this.state.ifShowAboutUs}
            bodyStyle={{
              height:ifMobile? height/2:height,
						  width:ifMobile? width:500
              // height: height,
              // width: width,
            }}
          >
            <div
              style={{
                height:ifMobile? height/2:height,
						    width:ifMobile? width:500,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: height,
                  float: "left",
                  padding: 20,
                  paddingTop: 55,
                }}
              >
                <div style={{ height: 0 }} />
                <AboutUs
                  width={500}
                />
                

            
                <div style={{ height: 10 }} />
  
                
                
              </div>
            </div>
          </Popup>

          
          
          
        </div>
      );
    }
  }
}

export default withRouter(App);
