

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Button,Input,message,Segmented,Space,Avatar} from 'antd';
import { Popup } from "antd-mobile";
import { env_serverAddress,domain} from "../../env";
import { loginUser, onLogin, tellUserToken, removeToken,ifHasToken } from "../../auth";
import { 
	MenuFoldOutlined,
	MessageOutlined,
	LockOutlined,
	SendOutlined,
	UserOutlined,
	AppstoreOutlined,
	FileOutlined,
	CloseOutlined,
	MenuOutlined,
	LeftOutlined,

} from '@ant-design/icons';
import logo_agentplus from "./static/logo_agentplus.png";
import ChatContent from "../../component/EmbeddingChatContent";
import ImageComponent from "../../component/ImageComponent";
import "./index.css"

//-------------------------------

const changeFavicon = (faviconURL) => {
	const link = document.querySelector("link[rel~='icon']");
	if (link) {
	  link.href = faviconURL;
	} else {
	  const newLink = document.createElement('link');
	  newLink.rel = 'icon';
	  newLink.href = faviconURL;
	  document.head.appendChild(newLink);
	}
  };

class App extends Component {


	
	//pinState
	state={
		embedding:false,
		ifShowAgent:true,
		history:[]

	}
	componentDidMount(){
		const queryParams = new URLSearchParams(this.props.location.search);
        const embedding = queryParams.get('embedding');
		const original = queryParams.get('original');
		this.setState({embedding:embedding})
		// this.fetchMetaData(original)

		this.runApp(embedding)
	}


	// pinRun
	runApp = async(gptToken) =>{
		//-----------------------------------------------------
		// 通过gpt的token,向后台获取gpt的公开数据    
		// const userToken = tellUserToken()
		const userToken = 0

		if(gptToken != 0 && gptToken != undefined){
			// 第一遍运行的时候储存起来gptToken
			this.setState({gptToken:gptToken})
			const publicData = await this.fetchPublicData(gptToken,userToken);
			if(publicData){
				this.setState({gptData:publicData.returnData})
				setTimeout(() => {this.setState({ 
					history:[{ role: "assistant", index: 1, content: publicData.returnData.gptHello }]
				})}, 100)
				document.title = publicData.returnData.gptName + "のページ"
				// 设置Favicon
				if(publicData.returnData.gptIcon != 0){
					changeFavicon(publicData.returnData.gptIcon);
				}
				
			}
		}else{
			console.log("no such agent")
		}
	}


	// pinFetchPublic
	fetchPublicData = async (gptToken,userToken) => {
		// console.log(userToken)
		this.setState({ifFetchingPublicData:true})
		if (gptToken != 0) {
		try {
			var url = env_serverAddress + "back/mygpt/fetchpublicdata";
			let response = await fetch(url, {
			method: "post",
			body: JSON.stringify({
				gptToken: gptToken,
				userToken:userToken
			}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
			headers: {
				"Content-Type": "application/json",
			},
			})
			let data = await response.json()
			console.log(data);
			return(data)
		} catch (error) {
			console.log(error)
			return(0)
		} finally{
			this.setState({ifFetchingPublicData:false})
		}
		}
		
	};


  //pinPost
  postMessage_callGpt = async (content) => {
    console.log("postMessage_callGpt--------------")
    var history = this.state.history;
    history.push({
      role: "user",
      time: Math.floor(Date.now() / 1000),
      content:content,
    });
    this.setState({ history: history });
   
    var url = env_serverAddress + "back/mygpt/postmessage_requiregpt"; //传值的地址
    let response = await fetch(url, {
      method: "post",
      body: JSON.stringify({
        content: content,
        userToken: 0,
        gptToken: this.state.gptToken,
        history:history, //仅仅用于游客
        password:0
      }), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    console.log(result);
    if (result.statusCode == 0) {
      message.error(this.state.current_gptName + "は現在、完全沈黙！");
    }
    if(result.statusCode == 1 || result.statusCode == 2){
      //如果是游客
      this.setState({ history: result.returnData }); 
    }
    if(result.statusCode == 8){
      message.error("権限はありません💦");  
    }
    
  };


  // pinDeleteMessage
  deleteMessage = async () => {

    // this.setState({lastTimeFetchedChatHistory:0})
    this.setState({ 
		history:[{ role: "assistant", index: 1, content: this.state.gptData.gptHello }]
	})
    
    
  };

//   fetchMetaData = async(url) => {
//     fetch(url)
//       .then(response => response.text())
//       .then(html => {
//         const doc = new DOMParser().parseFromString(html, 'text/html');
//         const metaTags = doc.querySelectorAll('meta');

// 		console.log(metaTags)
//         metaTags.forEach(tag => {
//           if (tag.getAttribute('name') === 'description') {
//             this.setState({ description: tag.getAttribute('content') });
//           }
//         });
//       })
//       .catch(error => {
//         console.error('Error fetching URL:', error);
//       });
//   };

	
	render() {
		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var ifMobile = width < height

		const queryParams = new URLSearchParams(this.props.location.search);
        const embedding = queryParams.get('embedding');
        const original = queryParams.get('original');
		console.log(embedding)
		console.log(original)

		var gptIcon = false
		if(this.state.gptData){
			if(this.state.gptData.gptIcon != 0){
				// console.log(this.state.gptData.gptIcon)
				gptIcon = this.state.gptData.gptIcon
			}
			
		}

		console.log(gptIcon)
		

		// pinReturn
		return(
		
			<div style={{
				width:width,height:height,
				position:"fixed",left:0,top:0,
				// backgroundColor:"#ff0000"
				}}>
				{/* embedding... */}

				<Space>
					{this.state.ifShowAgent && (
						
						<div style={{
							width:300,height:height,
							// overflow:"hidden",
							// boxShadow: '5px 0px 20px rgba(0, 0, 0, 0.5)', // x-offset, y-offset, blur-radius, color
							// position:"relative"
							// outline: '1px solid black',
  							// outlineOffset: '7px', // 可以根据需要调整描边粗细
							zIndex:999,
							// marginTop:60
							}}>
							

							{/* 状态栏目 */}
							<div
								style={{
								width: 308,
								backgroundColor: "#ffffff",
								height: 40,
								position: "absolute",
								top: 0,
								WebkitBackdropFilter: "blur(10px)",
								backdropFilter: "blur(10px)",
								backgroundColor: "rgba(255, 255, 255, 0.1)",
								zIndex:999,
								}}
								>
								<div
									style={{
										position: "absolute",
										left: 10,
										top: 10,
										width: 30,
										cursor: "pointer",
									}}
									>
									<MenuFoldOutlined
										style={{ fontSize: 20 }}
										onClick={()=>{
											this.setState({ifShowAgent:false})
										}}
									/>
								</div>
					
								<Space>
									<div style={{ 
										width: 200, height: 40, 
										// backgroundColor: "#ff0000" ,
										// width: 90,
										marginLeft: 300 / 2 - 45,
										marginTop: 5,
										}}>
										<a  href={domain} target="_blank" rel="noopener noreferrer">
											<img
												style={{
													width: 90,
												}}
												src={logo_agentplus}
											/>
										</a>
									</div>
								</Space>
            
								{/* <div
									style={{ width: 310, height: 1, position: "absolute", top: 16 }}
									>
									<Divider />
								</div> */}
								<div
									style={{ 
										width: 1, height: height, 
										backgroundColor:"#000000",
										position: "fixed", top: 0,left:308 }}
									>
								</div>
							</div>

							<ChatContent
								history = {this.state.history}
								gptData = {this.state.gptData}
								postMessage_callGpt={this.postMessage_callGpt}
								// showLogin={()=>{this.setState({ifShowLogin:true})}}
								deleteMessage = {this.deleteMessage}
								ifEmbedding = {true}
								width={300}
								/>  	
						</div>
					)}
					<iframe 
						style={{
							width:this.state.ifShowAgent ? width-310:width,
							height:height,
							// zIndex:1000,
							border:0}}
						// src="http://43.133.180.238:8080/?folder=/home/lighthouse/cpu-3"
						// src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12220.869057229575!2d-74.0059722!3d40.7127753!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDDCsDQyJzQ2LjEiTiA3NMKwMDBdMSczNS44Ilc!5e0!3m2!1szh-CN!2s!4v1633281790406!5m2!1szh-CN!2s"
						src={original}
						content="notranslate"
						allowfullscreen=""
						loading="lazy">
					</iframe>
					{/* {!ifMobile && this.state.ifShowAgent && (
						<Button 
							shape="circle"
							// type="primary"
							danger
							icon={<CloseOutlined/>}
							style={{
								backGroundColor:"#f1f1f1",
								position:"fixed",left:300-10,top:height/2-10,
								
							}}
							onClick={()=>{
								this.setState({ifShowAgent:false})
							}}
						/>
							
					)} */}

				</Space>

				{!this.state.ifShowAgent && gptIcon && (
					<div 
						style={{
						width:60,height:60,
						zIndex:999,
						position:"fixed",
						left:20,bottom:20,
						boxShadow: "0px 0px 5px 3px rgba(0, 0, 0, 0.3)",
						overflow:"hidden",
						borderRadius:30,
						cursor:"pointer",
						// animation: 'jelly 2s infinite'
						// animation: 'spin 2s linear infinite'
						// animation: 'jellyEffect 0.5s ease-in-out infinite'
						// animation: 'jellyEffect 2s ease-in-out infinite'
						animation: 'bounceEffect 2.5s ease-in-out infinite'
						}}
						onClick={()=>{
							this.setState({ifShowAgent:true})
						}}
						>
						<ImageComponent
							src={gptIcon}
							width={60}
							height={60}
							
						/>
					</div>
				)}
				
				{!this.state.ifShowAgent && !gptIcon && (
					<Button 
						style={{
						width:60,height:60,
						zIndex:999,
						position:"fixed",
						left:20,bottom:20,
						boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.5)",
						animation: 'jelly 1s infinite'
						}}
						type="primary"
						shape="circle"
						icon={<MessageOutlined/>}
						onClick={()=>{
							this.setState({ifShowAgent:true})
						}}
						/>
				)}
				

			</div>






			
			
			
			
		)

  	}
}

export default withRouter(App);