

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Button,Input,message} from 'antd';
import { Popup } from "antd-mobile";
import { Space} from 'antd';
// import { env_serverAddress,domain} from "../../env";
// import { loginUser, onLogin, tellUserToken, removeToken,ifHasToken } from "../../auth";
import { 
	MenuFoldOutlined,
	// LoadingOutlined, 
	// LeftOutlined, 
	// SettingOutlined,
	MenuOutlined,
	LockOutlined,
	PoweroffOutlined,
	UserOutlined

} from '@ant-design/icons';
import { Tranquiluxe } from "uvcanvas"
import logo_mtplus from "./static/logo_mtplus.png";

class App extends Component {


	
	//pinState
	state={


	}
	
	
	render() {

		//var height_memberBar = this.myRef_member_div.current.clientHeight

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var ifMobile = width < height;
	

		// pinReturn
		return(
		
			<div style={{width:width,height:height,position:"fixed",left:0,top:0}}
				>
				<div 
					style={{
					width:ifMobile ? width:500,			
					backgroundColor:"#ffffff",
					// height:height,
					float:"left",
					marginLeft:20,
					//padding:20,
					}}>
					
					<div style={{height:20}}/>
					{/* <div style={{fontSize:"15px",fontWeight:"bold"}}>
						About Us
					</div> */}
					{/* <div style={{height:10}}/> */}
					<div style={{
						width:ifMobile ? width-40:460,
						height:200,
						backgroundColor:"#f1f1f1",
						borderRadius:15,
						}}>

						<img src={logo_mtplus} style={{width:100,marginTop:65,marginLeft:ifMobile ? width/2-50-20:180}}/>
						{/* <div style={{height:5}}/>
						<div style={{
							width:ifMobile ? width-40:460,
							textAlign:"center",
							fontSize:"17px",fontWeight:"bold"
							}}>
							MT+
						</div> */}
						<div style={{height:5}}/>
						<div style={{
							width:ifMobile ? width-40:460,
							textAlign:"center",
							fontSize:"11px",
							// fontWeight:"normal"
							// fontWeight:"bold"
							}}>
							東大発AI探索チーム
						</div>
					</div>
					<div style={{height:10}}/>
					<Space>
						<div style={{fontSize:12,color:"d8d8d8"}}>
						BUG報告 / 業務連携
						</div>
						<Button type="link" style={{marginLeft:-15}}
							onClick={()=>{
								message.info("メールをコピーしました")
								copy("nin@mtplus.io")
							}}
							
						>
							nin@mtplus.io
						</Button>

					</Space>
					
					<div style={{height:50}}/>
					{/* <Tranquiluxe style={{marginLeft:-20,width:ifMobile ? width:500,height:150}}/> */}
					{/* //----------------------------------- */}
				
				</div>


				
			</div>






			
			
			
			
		)

  	}
}

export default withRouter(App);