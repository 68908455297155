import React, { Component } from "react";
import { PlusOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Input,Button,message,Space,Divider} from 'antd';
import {env_serverAddress,domain} from "../../../env"
import ReactMarkdown from "react-markdown";

class ImagesList extends Component {

  //pinS
  state = {
    searchValue:"",
    searchResult:"",
    fileList:[],
    ifLoading:false,
  };



  fetchRag = async()=>{
    this.setState({ifLoading:true})
		try{
			var url = env_serverAddress+'back/mygpt/devfetchrag'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					userToken:this.props.userToken,
					gptid:this.props.gptid,
          ask:this.state.searchValue
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json().then()
			console.log(data)
			if(data?.statusCode != 0){
        this.setState({
          searchResult:data.result,
          fileList:data.fileList
        })
			}
		}
		catch(error){
		}
    this.setState({ifLoading:false})
	}



  render() {

    var width = this.props.width
    var height = document.documentElement.clientHeight-100
    var ifMobile = height > width;

    
    return(
      <div
        style={{width:width,
          // height:height,
          // backgroundColor:"#ff0000"
        }}
      >
        <div style={{height:30}}/>
        <div style={{fontSize:"15px",fontWeight:"bold"}}>
          データベースを検索
        </div>
        <div style={{height:10}}/>
        <Input
          placeholder='Please enter content'
          // prefix={<UserOutlined/>}
          style={{
            width:width,
          }}
          maxLength={50}
          value={this.state.searchValue}
          onChange={(e)=>{
            this.setState({searchValue:e.target.value})
          }}
          />
        <div style={{height:10}}/>
        <Button type="primary" 
          onClick ={()=>{
            // this.setState({searchResult:this.state.searchValue})
            this.fetchRag()
          }}
        >Search</Button>
        <div style={{width:width,marginTop:30}}><Divider/></div>
        {this.state.ifLoading ? (
          <LoadingOutlined style={{fontSize:20}}/>
        ):(
          <div>
            {this.state.fileList.map((item)=>{
              return(
                <div>{item}</div>
              )
            })}
            <ReactMarkdown style={{userSelect:"text"}}>
              {this.state.searchResult}
            </ReactMarkdown>
          </div>
        )}
        
        

      
      </div>
    )
    
    
  }
}

export default ImagesList;
