
import React, { Component} from "react";

class ImageComponent extends Component {
    render() {
      const { src, width, height } = this.props;
      const imgStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      };
      const divStyle = {
        width: width,
        height: height,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      };
      return (
        <div style={divStyle}>
          <img src={src} alt="img" style={imgStyle} />
        </div>
      );
    }
  }
  
  export default ImageComponent;