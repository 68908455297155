





// export var env_serverAddress = "http://43.133.180.238:1001/"
// export var env_serverAddress = "https://dp-demo.nttdata-neuroai.com/"
export var env_serverAddress = "https://agent.hongoplus.com/"

export var domain = "https://agent.hongoplus.com"
// export var domain = "http://43.133.180.238:3001"
// export var domain = "https://dp-demo.nttdata-neuroai.com"
// export const cookie_key = "myGPT";
export const cookie_key_guest = "myGPT_guest"



 
export var ifShowContactus = 1
export var version = " 1.0.1"
export const InfoPoweredBy = "Powered by GPT,Gemini,Llama"
export const InfoDescription = "Our project leverages state-of-the-art large language models to provide enhanced services while prioritizing user privacy and security. By harnessing cutting-edge advancements in various prominent language models, we ensure that users receive superior service without compromising their privacy or security."