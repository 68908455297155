import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Skeleton } from 'antd';
import {
  SettingOutlined,
  LinkOutlined,
  AppstoreOutlined,
  CompassOutlined
} from '@ant-design/icons';
import ImageComponent from "../../../component/ImageComponent"




const count = 3;
const fakeDataUrl = `https://randomuser.me/api/?results=${count}&inc=name,gender,email,nat,picture&noinfo`;


const App = (props) => {
  // const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  // const [list, setList] = useState([]);
  
  useEffect(() => {
    console.log(props.list_robots)
    // fetch(fakeDataUrl)
    //   .then((res) => res.json())
    //   .then((res) => {
    //     // setInitLoading(false);
    //     setData(res.results);
    //     setList(res.results);
    //     console.log(res.results)
    //   });
  }, [props.list_robots]);

  return (
    <List
      style={{marginLeft:-10}}
      className="demo-loadmore-list" 
      loading={loading}
      itemLayout="horizontal"
      // loadMore={loadMore}
      dataSource={props.list_robots}
      renderItem={(item) => (
        <List.Item
          actions={[
          <LinkOutlined 
            style={{fontSize:25}}
            onClick={()=>{props.requireGptToken(item.gptid)}}
            />,
            
          ]}
        >
          <Skeleton avatar title={false} loading={props.loading} active>
            <List.Item.Meta
              onClick={()=>{props.handleClick(item)}}
              avatar={
              <div style={{borderRadius:30,overflow:"hidden",
              marginTop:12,width:60,height:60}}>

                {item.icon==0 ?(
                  <Avatar
                    style={{
                    backgroundColor:"#000000",
                    width:60,height:60,
                    }}
                    icon={<div style = {{marginTop:14}}><AppstoreOutlined style={{fontSize:30}}/></div>}
                    />	
                ):(
                  <ImageComponent src={item.icon} width={60} height={60}/>
                )}
                
                
              </div>}
              title={<p style={{height:10}}>{item.gptName}</p>}
              description={<p >{item.gptDescription}</p>}
            />
          </Skeleton>
        </List.Item>
      )}
    />
  );
};
export default App;