
import cookie from 'react-cookies'
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Tranquiluxe } from "uvcanvas"
import { Divider,Typography,Skeleton,Button ,message,Avatar,Input,Switch} from 'antd';
import { 
	GlobalOutlined,
	LoadingOutlined,
	TeamOutlined,
	UserOutlined,
	EyeOutlined,
	CloseOutlined,
	AppstoreOutlined,
	CheckOutlined,
	CompassOutlined,
	EyeInvisibleOutlined, 
	PlusOutlined,
	CloudUploadOutlined
} from '@ant-design/icons';

import { Popup} from 'antd-mobile'
// import banner_default from "../../static/bg.jpg"
// import banner from "../../static/bg.jpg"
// import { Space ,Dropdown} from 'antd';
// import Compo_card_small from "../../component/CardSmall";
// import Compo_card_middle from "../../component/CardMiddle";
// import ImageComponent from "../../component/ImageComponent"
// import icon_gpt from "../../static/gpticon.png"
// import icon_robot from "../../static/icon_robot.png"
import {env_serverAddress,domain} from "../../env"
// import {cookie_key} from "../../auth"
import { loginUser, onLogin, tellUserToken, removeToken,ifHasToken } from "../../auth"



import FileList from "./component/FileList"
import File from "./component/File"
// import NotebookList from "../../component/Notebook"

// import emoji_notebook from "../../static/emoji_notebook.png";
// import emoji_kiui from "../../static/emoji_kiui.png";
// import emoji_repeat from "../../static/emoji_repeat.png";


const { TextArea } = Input;
// const history = useHistory();

class App extends Component {

	constructor(props){
        super(props);
        this.myRef_input_uploadfile=React.createRef();

	}
	
	//pinState
	state={
		user_token:0,
		fileList:[],
		ifLoadingData:false,
		ifUploading:false,
		fileSelected:false,

	}


	
	delete = async(item) =>{
		console.log(item)
		try{
			var url = env_serverAddress+'back/mygpt/deletefile'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					userToken:tellUserToken(),
					fileid:item.fileid
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json()
			console.log(data)
			this.fetchfileListByOwnerid()
		}
		catch(error){}
		this.setState({ifShowEditFile:false})
		
	}

	retryAna = async(item) =>{
		this.setState({ifShowEditFile:false})
		// console.log(item)
		try{
			var url = env_serverAddress+'back/mygpt/retryanalysize'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					userToken:tellUserToken(),
					fileid:item.fileid
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json()
			console.log(data)
			this.fetchfileListByOwnerid()
		}
		catch(error){}
		this.setState({ifShowEditFile:false})
		
	}

	
	
	//pinFetch
	fetchfileListByOwnerid = async()=>{

		console.log("fetching...")
		//尝试获取token
		var userToken = tellUserToken()
		if(userToken != 0 && userToken != undefined){

			try{
				var url = env_serverAddress+'back/mygpt/fetchfilelist'
				let response= await fetch(url,{
					method: 'post',
					body: JSON.stringify({
						userToken:userToken,
					}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
					headers: {
						'Content-Type': 'application/json'
					}
				})
				let data = await response.json()

				console.log(data)
				if(data){
					this.setState({fileList:data.returnData})
				}
			}
			catch(error){
			}
		}
		this.setState({ifLoadingData:false})


		
	}
	componentDidMount(){
		console.clear()
		this.fetchfileListByOwnerid()

		this.intervalId = setInterval(this.fetchfileListByOwnerid, 4000)
	}
	componentWillUnmount() {
		// 组件卸载前清除定时器
		clearInterval(this.intervalId);
	  }

	


	handleUploadAfterSync = async(e)=> {
		try{
			e.preventDefault();	
			var url = env_serverAddress+"back/mygpt/uploadfile"
			let file = e.target.files[0];
			const formdata = new FormData();
			this.setState({ifUploading:true})

			// 一般上传--------------------------------------------------------
			
			var info = JSON.stringify({
				userToken: tellUserToken()
			})
			formdata.append('file', file);
			formdata.append('info', info);


			let response= await fetch(url,{
				method: 'post',
				body: formdata,
			})
			let result = await response.json()
			console.log(result)

			if(result){
				if(result.statusCode != 0){
					message.config({top: 3,duration: 2,});message.success('image uploaded!');
				}
			}
			
			
			
		}
		catch(error){
			this.setState({ifUploading:false});this.fetchfileListByOwnerid()
			message.config({top: 3,duration: 2,});message.error('image too large');
		}
	}
	handleUploadFile = (e)=> {
		//this.uploadDataset()
		if(this.state.ifUploading == false){
			message.config({top: 3,duration: 2,});
			message.info('uploading, please wait');
			this.handleUploadAfterSync(e).then(()=>{
				this.setState({ifUploading:false})
				this.fetchfileListByOwnerid()
			})
		}
		else{message.config({top: 3,duration: 2,});message.info('uploading,please wait');}		
	}

	handleClick_pullup_input_uploadfile = () => {
		this.myRef_input_uploadfile.current.click();
	}






	


	
	render() {
		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var ifMobile = width < height;
		// pinReturn


		return(
		
			<div 
				style={{
				height:this.props.height,
				width:this.props.width,
				//backgroundColor:"#ffff00",
				overflow:"hidden",
				overflowX:"hidden"
				}}>	
				<div 
					style={{
					height:"auto",
					marginLeft:5,
					//marginTop:20,
					width:this.props.width,
					//backgroundColor:"#ffff00",
					overflowY:"hidden",
					overflowX:"hidden",
					//display: "inline-block",
			
					}}>
					{/* -------------------------------------------------- */}

					
					
					
					<div 
						style={{
						width:this.props.width,
						display: "inline-block",
						overflowX:"hidden",
						overflowY:"hidden",
						}}>
						
						<div style={{overflowY:"scroll",height:this.props.height-60}}>


							<FileList 
								fileList={this.state.fileList}
								loading = {this.state.ifLoadingData}
								delete = {this.delete}
								retryAna = {this.retryAna}
								showEditFile={(item)=>{
									this.setState({ifShowEditFile:true,fileSelected:item})
									// console.log(item)
								}}
								// requireGptToken={this.requireGptToken}
					 		/>
							<div style={{height:100}}/>
							
						</div>
					</div>
					

				</div>

				<Button
					shape="circle"
					type="primary"
					style={{
						backgroundColor:"#F4CC47",
						color:"#000000",
						position: "fixed",
						right: 20,
						bottom: 20,
						height: 60,
						width: 60,
						fontWeight: "bold",
						boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
					}}
					onClick={() => {
						this.handleClick_pullup_input_uploadfile()
					}}
					icon={<PlusOutlined />}
				/>


				<p>
					<input 
						id = "uploadimg" type="file" accept=".pdf"
						onChange={this.handleUploadFile}
						ref={this.myRef_input_uploadfile}
						style={{width:this.state.width_RightBar,display:"none",}}
						/>
				</p>
				












				


				{/* pinReference */}
				<Popup
					position={ifMobile? 'bottom':"right"}
					onMaskClick={() => {
						this.setState({ifShowEditFile:false})
					  }}
					onClose={() => {
						this.setState({ifShowEditFile:false})
					}}
					showCloseButton
					visible={this.state.ifShowEditFile}
					bodyStyle={{
					height:ifMobile? height*0.85:height,
					width:ifMobile? width:500
					}}
					>
					<div style={{
						height:ifMobile? height*0.85:height,
						overflowX:"hidden",
						overflowY:"auto",
						width:ifMobile? width:500,
						// position:"relative"
						}}>
						
						<div
							style={{
								height:"auto",
								overflowX:"hidden",
								overflowY: 'scroll',
								// padding:20,
								marginLeft:20,
								//backgroundColor:"#79D760",
								width:ifMobile? width-40:460}}>
							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								Reference
							</div>
							<div style={{height:10}}/>
							<File
								gptid = {this.state.gptid_current}
								user_token = {this.state.user_token}
								file = {this.state.fileSelected}
								delete = {this.delete}
								retryAna = {this.retryAna}
							/>
							<div style={{height:100}}/>
						</div>
					</div>
				</Popup>
				

				


				
				

			</div>






			
			
			
			
		)

  	}
}

export default withRouter(App);