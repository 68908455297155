

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
// import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Tooltip} from 'antd';

import { Space, 
	// Upload ,Popover,Menu,
	Avatar} from 'antd';
// import { Button} from 'antd';
// import { message,Drawer} from 'antd';
// import * as CryptoJS from 'crypto-js'
// import cookie from 'react-cookies'
// import { MailOutlined, SettingOutlined } from '@ant-design/icons';
// import banner from "../static/bg.jpg"

import { 
	RightOutlined,
	LoadingOutlined,
	UserOutlined,
	UserAddOutlined,
	InfoCircleOutlined,
	AppstoreOutlined,
	PlusCircleOutlined,
	CloudUploadOutlined,
	PlusOutlined,
} from '@ant-design/icons';


// const { Title, Paragraph, Text, Link } = Typography;




class Compo_card_system_info_message extends Component {


	
	//pinState
	state={
		//switch_ifSureLeaveroom:false,
	}
	
	
	render() {

		//var height_memberBar = this.myRef_member_div.current.clientHeight

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		
		var title = this.props.type
		var message = this.props.message
		var icon = this.props.icon

		if(title === undefined){title = "undefined"}
		if(message === undefined){message = "undefined"}
		if(icon === undefined || icon == 0){icon = false}

		var infoType = "small"


		var iconType = "img"
		if(icon == "newRobot"){
			iconType = "newRobot"
		}
		if(icon == "importRobot"){
			iconType = "importRobot"
		}

		if(infoType == "small"){
			return(
				<div 
					style={{
					height:10,
					width:500,
					marginBottom:25,
					marginTop:-5,
					//backgroundColor:"#ffff00"
					}}>	
					<Space>
						<div
							style={{width:47}}
							>

						</div>
						<div
							style = {{color:"#d8d8d8"}}
							>
							<InfoCircleOutlined />{"  "}{this.props.message} {" - "}{this.props.date}
						</div>
					</Space>
					{/* <div style={{height:10}} /> */}
					
				</div>
			)
		}
		else{
			// pinReturn
			return(
				<div 
					style={{
						
					height:90,
					width:250,
					//backgroundColor:"#ffff00"
					}}>
					<div 
						onClick = {this.props.callBack}
						style={{
							
							margin:0,
							backgroundColor:"#e3e3e3",
							height:80,
							borderRadius:15,
							cursor:"pointer"
							
						}}>
							<div style={{height:10}} />
						<Space style={{paddingLeft:10}}>
							{icon ?(

								<div>
									{iconType=="newRobot" ?(
										<Avatar
											style={{
											backgroundColor:"#000000",
											width:60,height:60,
											}}
											icon={<PlusOutlined style={{fontSize:30,marginTop:15}}/>}
											/>
									):(
										""
									)}

									{iconType=="importRobot" ?(
										<Avatar
											style={{
											backgroundColor:"#000000",
											width:60,height:60,
											}}
											icon={<CloudUploadOutlined style={{fontSize:30,marginTop:15}}/>}
											/>
									):(
										""
									)}
									
									{iconType=="img" ?(
										<img src={icon} style={{width:60}} />
									):(
										""
									)}


								</div>
								
								
							):(
								<Avatar
									style={{
									backgroundColor:"#000000",
									width:60,height:60,
									}}
									icon={<UserOutlined style={{fontSize:30,marginTop:15}}/>}
									/>
							)}
							
							<div style={{height:60,width:130,marginLeft:7}}>

								<div style={{height:7}} />
								<div style={{fontSize:18,fontWeight:"bold",height:23,overflow:"hidden",whiteSpace:"nowrap"}}>
								
									<Tooltip title={title}>
										{title}
									</Tooltip>
								</div>
								<div style={{fontSize:12,paddingTop:3,height:20,overflow:"hidden",whiteSpace:"nowrap"}}>
									<Tooltip title={message}>
										{message}
									</Tooltip>
								</div>
							</div>
						</Space>
						{/* <div style={{height:20}}/> */}

						
					</div>
				</div>
			)	
		}
		

  	}
}

export default withRouter(Compo_card_system_info_message);