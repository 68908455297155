import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Skeleton,Popconfirm } from 'antd';
import {
  SettingOutlined,
  LoadingOutlined,
  AppstoreOutlined,
  CloudDownloadOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import ImageComponent from "../../../component/ImageComponent"

// import emoji_notebook from "../static/emoji_notebook.png";
import emoji_books from "../static/emoji_books.png";

const App = (props) => {
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    console.log(props.fileList)
   
  }, [props.list_robots]);

  return (
    <List
      style={{marginLeft:-10}}
      className="demo-loadmore-list" 
      loading={loading}
      itemLayout="horizontal"
      // loadMore={loadMore}
      dataSource={props.fileList}
      renderItem={(item) => (
        <List.Item
          actions={[
            // <div style={{
            //   fontSize:12,
            //   // position:"absolute",top:50,left:10,
            //   height:13,
            //   backgroundColor:"#31D870",borderRadius:10,color:"#ffffff",
            //   padding:7,paddingTop:2.5,
            //   }}>
            //   <LoadingOutlined/>{" "}Loading{" "}
            //   {/* {props.file.status} */}
            // </div>,
            // <Popconfirm
            //   title="Delete the file"
            //   description="Are you sure to delete this file?"
            //   onConfirm={()=>{props.delete(item)}}
            //   // onCancel={cancel}
            //   okText="Yes"
            //   cancelText="No"
            // >
            //   <DeleteOutlined 
            //     style={{fontSize:25,color:"#ff0000"}}
            //     // onClick = {()=>{props.delete(item)}}
            //   />
            // </Popconfirm>,
            <div>
              {item.status == "error" && (
                <CloseCircleOutlined 
                  style={{fontSize:25,color:"#ff0000"}}
                />
              )}
              {item.status == "done" && (
                <CheckCircleOutlined 
                  style={{fontSize:25,color:"#31D870"}}
                />
              )}
              {item.status == "loading" && (
                <LoadingOutlined 
                  style={{fontSize:25}}
                />
              )}
            </div>
            
            
            
            
          
            
          ]}
        >
          <Skeleton avatar title={false} loading={props.loading} active>
            <List.Item.Meta
              onClick={()=>{props.showEditFile(item)}}
              avatar={
              <div style={{
                // borderRadius:30,overflow:"hidden",
                marginTop:15,
                width:50,height:50
              }}>

                <ImageComponent src={emoji_books} width={50} height={50}/>
                  
                  
              </div>}
              title={<p style={{height:10}}>{item.name}</p>}
              description={<p >{item.type}</p>}
            />
          </Skeleton>
        </List.Item>
      )}
    />
  );
};
export default App;