import { Avatar, Divider, List } from 'antd';
import ImageComponent from "../../../component/ImageComponent"
import emoji_books from "../static/emoji_books.png";
import React, { useEffect, useState } from 'react';
import {env_serverAddress,cookie_key,domain} from "../../../env"
import { Popup} from 'antd-mobile'
import { Input,Button,message,Dropdown,Space,Popconfirm} from 'antd';
import { RetweetOutlined,CheckOutlined,LoadingOutlined,CloudDownloadOutlined,CloseOutlined ,DeleteOutlined} from '@ant-design/icons';


const { TextArea } = Input;
const App = (props) => {

  const [reference,setReference] = useState({})
  const [fileList,setFileList] = useState([])
  const [ifLoading,setIfLoading] = useState(false)
  // const [currentNote,setCurrentNote] = useState({title:"xxx",content:"xxxx"})
  // const [ifEditedNote,setIfEditedNote] = useState(false)

  const [width,setWidth] = useState(0)
  const [height,setHeight] = useState(0)
  const [ifMobile,setIfMobile] = useState(false)

  useEffect(() => {

    setWidth(document.documentElement.clientWidth)
    setHeight(document.documentElement.clientHeight)
    setIfMobile(document.documentElement.clientWidth < document.documentElement.clientHeight)

    // fetchGPTReference()
   
  }, [props]);


  // const fetchGPTReference = async()=>{
  //   setIfLoading(true)
	// 	try{
	// 		var url = env_serverAddress+'back/mygpt/fetchgptreference'
	// 		let response= await fetch(url,{
	// 			method: 'post',
	// 			body: JSON.stringify({
	// 				userToken:props.user_token,
	// 				gptid:props.gptid,
	// 			}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
	// 			headers: {
	// 				'Content-Type': 'application/json'
	// 			}
	// 		})
	// 		let data = await response.json().then()
	// 		console.log(data)
	// 		if(data.statusCode != 0){
  //       setReference(data.returnData)
	// 		}
	// 	}catch(error){}

  //   // 获取fileList
  //   try{
  //     var url = env_serverAddress+'back/mygpt/fetchfilelist'
  //     let response= await fetch(url,{
  //       method: 'post',
  //       body: JSON.stringify({
  //         userToken:props.user_token,
  //       }), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //     let data = await response.json()
  //     console.log(data)
  //     if(data){

  //       setFileList(data.returnData)
  //     }
  //   }catch(error){}

  //   setIfLoading(false)
	// }

  
  
  // const handleUpdate = async (item) =>{
  //   console.log(item)
  //   setIfLoading(true)

	// 	try{
	// 		var url = env_serverAddress+'back/mygpt/editreference'
	// 		let response= await fetch(url,{
	// 			method: 'post',
	// 			body: JSON.stringify({
	// 				userToken:props.user_token,
	// 				gptid:props.gptid,
  //         newData:item
	// 			}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
	// 			headers: {
	// 				'Content-Type': 'application/json'
	// 			}
	// 		})
	// 		let data = await response.json().then()
	// 		console.log(data)
	// 		if(data.statusCode != 0){
  //       message.success('done!　😆');
  //       fetchGPTReference()
	// 		}
	// 	}
	// 	catch(error){
	// 	}
  //   setIfLoading(false)
  // }

  console.log(props.file)

  return(

    <div>
      {ifLoading?(
        <LoadingOutlined style={{fontSize:20}}/>
  
      ):(
        <div>
         
          <div style={{width:ifMobile? width-40:460,height:2,marginTop:-20}}><Divider/></div>
          <div style={{height:15}}/>

          <div>
            {reference.fileid != 0 ?(
              <Space>
              <ImageComponent src={emoji_books} width={70} height={70}/>
              <div style={{width:2}}/>
              <div style={{width:ifMobile? width-40-80:460-80,height:70,position:"relative"}}>


                
                <div style={{fontSize:20,position:"absolute",top:1,left:10,fontWeight:"bold",whiteSpace:"nowrap"}}>
                  {props.file.name}
                </div>
                <div style={{fontSize:12,position:"absolute",top:31,left:10}}>
                  {props.file.fileid}
                </div>
                {props.file.status == "done" && (
                  <div style={{
                    fontSize:12,position:"absolute",top:50,left:10,
                    height:13,
                    backgroundColor:"#31D870",borderRadius:10,color:"#ffffff",
                    padding:5,paddingTop:3.5,paddingRight:8
                    }}>
                      
                      <CheckOutlined/>{" "}Done{" "}
                    {/* {props.file.status} */}
                  </div>
                )}
                {props.file.status == "error" && (
                  <div style={{
                    fontSize:12,position:"absolute",top:50,left:10,
                    height:13,
                    backgroundColor:"#ff0000",borderRadius:10,color:"#ffffff",
                    padding:5,paddingTop:3.5,paddingRight:8
                    }}>
                      
                      <CloseOutlined/>{" "}Error{" "}
                    {/* {props.file.status} */}
                  </div>
                )}
                {props.file.status == "loading" && (
                  <div style={{
                    fontSize:12,position:"absolute",top:50,left:10,
                    height:13,
                    backgroundColor:"#000000",borderRadius:10,color:"#ffffff",
                    padding:5,paddingTop:3.5,paddingRight:8
                    }}>
                      
                      <LoadingOutlined/>{" "}Analyzing{" "}
                    {/* {props.file.status} */}
                  </div>
                )}
                
              </div>
            </Space>
            ):(
              <Space>
              <ImageComponent src={emoji_books} width={70} height={70}/>
              <div style={{width:2}}/>
              <div style={{width:ifMobile? width-40-80:460-80,height:70,position:"relative"}}>
                <div style={{fontSize:20,position:"absolute",top:25,left:10,fontWeight:"bold",whiteSpace:"nowrap"}}>
                  No Reference
                </div>
              </div>
            </Space>
            )}
            
          </div>

          <div style={{height:15}}/>
          <div style={{width:ifMobile? width-40:460,height:2,marginTop:-20}}><Divider/></div>
          <div style={{height:5}}/>
          <div style={{fontSize:"15px",fontWeight:"bold"}}>
						Setting
          </div>
          <div style={{height:15}}/>

          {props.file.status == "error" && (
            <div style={{
              // backgroundColor:"#eeeeee",
              backgroundColor:0 == reference.fileid ? "#c8c8c8":"#f8f8f8",
              color:0 == reference.fileid ? "#ffffff":"#000000",
              borderRadius:15,
              marginBottom:5,
              // marginTop:5,
              height:33,
              cursor:"pointer"
              }}
              onClick = {()=>{props.retryAna(props.file)}}
              >
              <Space style={{marginTop:1,marginLeft:10,height:30}}>
                <RetweetOutlined style={{fontSize:18,marginTop:4}}/>
                <div style={{fontSize:15}}>Retry Analysize</div>
                <Button style={{fontSize:15}} type="ghost"/>
              </Space>
            </div>
          )}
          
          
          <div style={{
            // backgroundColor:"#eeeeee",
            backgroundColor:0 == reference.fileid ? "#c8c8c8":"#f8f8f8",
            color:0 == reference.fileid ? "#ffffff":"#000000",
            borderRadius:15,
            marginBottom:5,
            // marginTop:5,
            height:33,
            }}
            // onClick = {()=>{handleUpdate({fileid:0,name:"",url:""})}}
            >
            <a href={props.file.url} style={{color:"#000000"}}>
              <Space style={{marginTop:1,marginLeft:10,height:30}}>
                <CloudDownloadOutlined style={{fontSize:18,marginTop:4}}/>
                <div style={{fontSize:15}}>Download File</div>
                <Button style={{fontSize:15}} type="ghost"/>
              </Space>
            </a>
          </div>
          <div style={{
            // backgroundColor:"#eeeeee",
            backgroundColor:0 == reference.fileid ? "#c8c8c8":"#f8f8f8",
            color:0 == reference.fileid ? "#ffffff":"#000000",
            borderRadius:15,
            marginBottom:5,
            // marginTop:5,
            height:33,
            }}
            // onClick = {()=>{handleUpdate({fileid:0,name:"",url:""})}}
            >
            <Popconfirm
              title="Delete the file"
              description="Are you sure to delete this file?"
              onConfirm={()=>{props.delete(props.file)}}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a href={props.file.url}>
                <Space style={{marginTop:1,marginLeft:10,height:30}}>
                  <DeleteOutlined style={{fontSize:18,marginTop:2,color:"#ff0000"}}/>
                  <div style={{fontSize:15,color:"#ff0000"}}>Delete File</div>
                  <Button style={{fontSize:15}} type="ghost"/>
                </Space>
              </a>

            </Popconfirm>
            
          </div>
          

        </div>


      )}
      


    </div>
    

  )
  
};
export default App;