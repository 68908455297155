import React, { Component } from "react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { ImageViewer } from "antd-mobile";

class ImagesList extends Component {
  constructor(props) {
    super(props);
    this.myRef_input_uploadImage = React.createRef();
  }
  //pinS
  state = {
    ifShowImageViewer: false,
    urlSelected: "",
  };

  handleClick_pullup_input_uploadImage = () => {
    //console.log("haha")
    this.myRef_input_uploadImage.current.click();
  };

  render() {
    var width = document.documentElement.clientWidth;
    var height = document.documentElement.clientHeight;
    var ifMobile = height > width;

    const { urls, uploadPostImage, removePostImage } = this.props;
    const imageWidth = this.props.width / 3; // 计算每张图片的宽度

    const containerStyle = {
      display: "grid",
      gridTemplateColumns: `repeat(3, ${imageWidth}px)`,
      gridTemplateRows: `repeat(auto-fill, ${imageWidth}px)`,
      gridGap: "5px",
    };

    if (urls.length == 1) {
      return (
        <div style={{position:"relative"}}>
          <img
            src={urls[0]}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            onClick={() => {
              this.setState({
                urlSelected: urls[0],
                ifShowImageViewer: true,
              });
            }}
          />
          {removePostImage && (
            <CloseOutlined
              style={{
                color: "#ff0000",
                position: "absolute",
                left: 5,
                top: 5,
                fontSize: 15,
              }}
              onClick={() => {
                removePostImage(urls[0]);
              }}
            />
          )}
          {uploadPostImage && urls.length < 9 && (
            <div
              style={{
                width: `${imageWidth}px`,
                height: `${imageWidth}px`,
                overflow: "hidden",
              }}
            > 
              {/* <div style={{height:10}}/> */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  backgroundColor: "#ffffff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // marginTop:10
                }}
              >
                <PlusOutlined
                  onClick={this.handleClick_pullup_input_uploadImage}
                  style={{ fontSize: 50, color: "#d8d8d8" ,
                }}
                />
              </div>
            </div>
          )}
          <p>
            <input
              id="uploadimg"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={uploadPostImage}
              ref={this.myRef_input_uploadImage}
              style={{ display: "none" }}
            />
          </p>

          {this.props.ifShowImageViewer && (
            <ImageViewer
              style={{ position: "fixed", top: 0, left: 0 }}
              classNames={{
                mask: "customize-mask",
                body: "customize-body",
              }}
              image={this.state.urlSelected}
              visible={this.state.ifShowImageViewer}
              onClose={() => {
                this.setState({ ifShowImageViewer: false });
              }}
            />
          )}
        </div>
      );
    } else {
      return (
        <div style={containerStyle}>
          {urls.map((url, index) => (
            <div
              key={index}
              style={{
                width: `${imageWidth}px`,
                height: `${imageWidth}px`,
                overflow: "hidden",
                position: "relative",
              }}
            >
              <img
                src={url}
                alt={`image-${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                onClick={() => {
                  this.setState({
                    urlSelected: url,
                    ifShowImageViewer: true,
                  });
                }}
              />
              {removePostImage && (
                <CloseOutlined
                  style={{
                    color: "#ff0000",
                    position: "absolute",
                    left: 5,
                    top: 5,
                    fontSize: 15,
                  }}
                  onClick={() => {
                    removePostImage(url);
                  }}
                />
              )}
            </div>
          ))}
          {uploadPostImage && urls.length < 9 && (
            <div
              style={{
                width: `${imageWidth}px`,
                height: `${imageWidth}px`,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  backgroundColor: "#ffffff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PlusOutlined
                  onClick={this.handleClick_pullup_input_uploadImage}
                  style={{ 
                    fontSize: 50, 
                    color: "#d8d8d8",
                  }}
                />
              </div>
            </div>
          )}
          <p>
            <input
              id="uploadimg"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={uploadPostImage}
              ref={this.myRef_input_uploadImage}
              style={{ display: "none" }}
            />
          </p>
          {this.props.ifShowImageViewer && (
            <ImageViewer
              style={{ position: "fixed", top: 0, left: 0 }}
              classNames={{
                mask: "customize-mask",
                body: "customize-body",
              }}
              image={this.state.urlSelected}
              visible={this.state.ifShowImageViewer}
              onClose={() => {
                this.setState({ ifShowImageViewer: false });
              }}
            />
          )}
        </div>
      );
    }
  }
}

export default ImagesList;
