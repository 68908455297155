import cookie from "react-cookies";
const cookie_key = "myGPT";


export const loginUser = () => {
  var temp = 0;
  try {
    var temp = cookie.load(cookie_key);
  } catch (error) {
    temp = 0;
  }
  return temp;
};

// 用户登录，保存cookie
export const onLogin = (token) => {
  cookie.save(cookie_key, token, { path: "/" });
};

export const tellUserToken = () => {
  var token = 0
  try{
    token = cookie.load(cookie_key)
  }catch(error){}
  if(token == null){token = 0}
  return token;
};
export const removeToken = () => {
  cookie.remove(cookie_key, { path: "/" });
};



export function ifHasToken() {
  var token_here = cookie.load(cookie_key)	
  if(token_here != undefined){
    return true
  }
  else{
    return false
  }

}