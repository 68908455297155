import { Avatar, Divider, List } from 'antd';
import ImageComponent from "../../../component/ImageComponent"
import React, { useEffect, useState } from 'react';
import {env_serverAddress,cookie_key,domain} from "../../../env"
import { Popup} from 'antd-mobile'
import { Input,Button,message,Dropdown,Space} from 'antd';
import { CheckCircleOutlined,SettingOutlined,LoadingOutlined,CloudDownloadOutlined,CheckOutlined ,DownOutlined} from '@ant-design/icons';
import emoji_books from "../../../static/emoji_books.png";

const { TextArea } = Input;
const App = (props) => {

  const [reference,setReference] = useState([])
  const [fileList,setFileList] = useState([])
  const [ifLoading,setIfLoading] = useState(false)
  // const [currentNote,setCurrentNote] = useState({title:"xxx",content:"xxxx"})
  // const [ifEditedNote,setIfEditedNote] = useState(false)

  // const [width,setWidth] = useState(0)
  // const [height,setHeight] = useState(0)
  const [ifMobile,setIfMobile] = useState(false)
  const [width, setWidth] = useState({
    width: window.innerWidth,
  });
  // useEffect(() => {

  //   setWidth(document.documentElement.clientWidth)
  //   setHeight(document.documentElement.clientHeight)
  //   setIfMobile(document.documentElement.clientWidth < document.documentElement.clientHeight)

  //   fetchGPTReference()
   
  // }, [props]);


  useEffect(() => {
    const handleResize = () => {
      setWidth({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setIfMobile(document.documentElement.clientWidth < document.documentElement.clientHeight)
    };

    window.addEventListener('resize', handleResize);
    fetchGPTReference()
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const fetchGPTReference = async()=>{
    setIfLoading(true)
		try{
			var url = env_serverAddress+'back/mygpt/fetchgptreference'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					userToken:props.user_token,
					gptid:props.gptid,
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json().then()
			console.log(data)
			if(data.statusCode != 0){
        setReference(data.returnData)
			}
		}catch(error){}

    // 获取fileList
    try{
      var url = env_serverAddress+'back/mygpt/fetchfilelist'
      let response= await fetch(url,{
        method: 'post',
        body: JSON.stringify({
          userToken:props.user_token,
        }), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
        headers: {
          'Content-Type': 'application/json'
        }
      })
      let data = await response.json()
      console.log(data)
      if(data){

        setFileList(data.returnData)
      }
    }catch(error){}

    setIfLoading(false)
	}

  
  


  // const onChange_value = (e) =>{
  //   const newvalue = e.target.value
  //   setCurrentNote({
  //     id:currentNote.id,
  //     title:currentNote.title,
  //     content:newvalue
  //   })
  //   setIfEditedNote(true)
  //   console.log(e.target.value)
  // }

  // const onChange_title = (e) =>{
  //   const newvalue = e.target.value
  //   setCurrentNote({
  //     id:currentNote.id,
  //     title:newvalue,
  //     content:currentNote.content
  //   })
  //   setIfEditedNote(true)
  //   console.log(e.target.value)
  // }


  const handleUpdate = async (item,ifOnList) =>{
    console.log(item)
    setIfLoading(true)
    var command = "add"
    if(ifOnList){
      command = "remove"
    }

		try{
			var url = env_serverAddress+'back/mygpt/editreference'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					userToken:props.user_token,
					gptid:props.gptid,
          fileid:item.fileid,
          command:command
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json().then()
			console.log(data)
			if(data.statusCode != 0){
        message.success('done!　😆');
        fetchGPTReference()
			}
		}
		catch(error){
		}
    setIfLoading(false)
  }



  return(

    <div>
      {ifLoading?(
        <LoadingOutlined style={{fontSize:20}}/>
  
      ):(
        <div 
          style={{position:"relative"}}
        >
          {/* <div style={{height:10}}/>
          <div style={{fontSize:"15px",fontWeight:"bold"}}>
						Title
          </div> */}
          {/* <div style={{width:ifMobile? width-40:460,height:2,marginTop:-20}}><Divider/></div>
          <div style={{height:15}}/> */}

          {fileList.length !=0 ? (
            <List
              style={{cursor:"pointer"}}
              itemLayout="horizontal"
              dataSource={fileList.filter(item => item.status === 'done')}
              renderItem={(item, index) => (
                <List.Item style={{marginLeft:-25}}
                  actions={[
                    reference.includes(item.fileid) ? (<CheckCircleOutlined style={{color:"#31D870",marginRight:-30}}/>):(""),
                    ]}
                  >
                  
                  <List.Item.Meta
                    onClick = {()=>{handleUpdate(item,reference.includes(item.fileid))}}
                    avatar={
                    <div style={{marginTop:19,width:40,height:40,opacity:reference.includes(item.fileid) ? 1:0.2}} >
                      <ImageComponent src={emoji_books} width={40} height={40}/>
                    </div>}
                    title={<div style={{color:reference.includes(item.fileid) ? "#000000":"#d8d8d8",}}>{item.name}</div>}
                    description={reference.includes(item.fileid) ? "in use":"click to enable"}
                  />
                </List.Item>
              )}
            />
          ):("")}
          
          {props.ifShowDev && (
            <Button
              onClick = {()=>{props.openIfShowDev()}}
              type="link"
              icon={<SettingOutlined />}
              style={{marginLeft:-15,
                position:"absolute",top:-33,right:-5
              }}
            >dev</Button>
          )}
          
          

        </div>


      )}
      


    </div>
    

  )
  
};
export default App;