import React, { Component } from "react";
import copy from "copy-to-clipboard";
import {
  Space,
  Upload,
  Popover,
  notification,
  Tooltip,
  Avatar,
  Image,
  Divider,
  Button,
  message,
  Popconfirm
} from "antd";
import ReactMarkdown from "react-markdown";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { TextArea ,Popup} from "antd-mobile";
import { loginUser, onLogin, tellUserToken, removeToken,ifHasToken } from "../auth";
import ImageComponent from "./ImageComponent";
import gpticon from "../static/gpticon.png";
import {
  formatDate,
  formatHourAndMinute,
  formatDate_tellDay,
  formatDate_tellDate,
  tellifyesterday,
  isValidUrl,
  ifPressCommandAndEnter,
  containsEmoji,
  containsSQLInjection,
  extractJSONsAndText,
} from "../tool";
import {
  RightOutlined,
  LoadingOutlined,
  SettingOutlined,
  UserOutlined,
  UserAddOutlined,
  CloseOutlined,
  AppstoreOutlined,
  CopyOutlined,
  FileTextOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import SystemInfo from "./SystemInfo";



const ImageRenderer = ({ src, alt }) => (
  <img 
    src={src} 
    alt={alt}
    style={{ width: '100%', maxWidth: 300}} // 你可以根据需要调整宽度
  />
);


//pin1
export default class App extends Component {
  constructor(props) {
    super(props);
    this.consoleEndRef = React.createRef();
    this.myRef_input_uploadImage = React.createRef();
  }

  state = {
    inputValue: "",
    ifShowReferenceDetail:false,
    referenceDetail:"",
  };

  handleClick_pullup_input_uploadImage = () => {
    //console.log("haha")
    this.myRef_input_uploadImage.current.click();
  };

  scrollToBottom = () => {
    this.consoleEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  postMessage_callGpt = () => {
    if (this.state.inputValue == "") {
      message.error("Please input first");
    } else {
      this.props.postMessage_callGpt(this.state.inputValue);
      this.setState({ inputValue: "" });
    }
  };
  postImage = (e) => {
    this.props.postImage(e);
  };

  componentDidUpdate() {
    this.scrollToBottom();
  }

  shortCut_postMessage = (event) =>{
    ifPressCommandAndEnter(event, () => {
      console.log("Command + Enter pressed!");
      this.postMessage_callGpt()
    });
  }

  componentDidMount() {
    this.timer1 = setTimeout(() => {
      // this.scrollToBottom_firstLoad();
      this.scrollToBottom();
    }, 100);
    //注册快捷键---------------------------------------------
    // 按下command/control + enter键的话
    document.addEventListener("keydown",this.shortCut_postMessage)
  }
  componentWillUnmount() {
    clearTimeout(this.timer1);
    document.removeEventListener("keydown", this.shortCut_postMessage);
  }

  render() {
    var width = document.documentElement.clientWidth;
    var height = document.documentElement.clientHeight;
    var width_leftBar = 200; //200

    var ifMobile = width < height;
    if (ifMobile) {
      width_leftBar = 0;
    }

    var width_middle = width - width_leftBar;

    return (
      <div
        style={{
          backgroundColor: "#ffffff",
          display: "flex",
          width: width,
          height: height,
          overflow: "hidden",
          // overflowY: "auto",
        }}
      >
        <div
            style={{
              width: width_middle,
              backgroundColor: "#ffffff",
              height: height,
              float: "left",
              // paddingTop: 40,
              // WebkitTapHighlightColor: 'rgba(0,0,0,0)',
              // WebkitTouchCallout: 'none',
              // WebkitUserSelect: 'none'
            }}
          >
            <div
              // 聊天记录--------------------------------------------------
              id="msg"
              style={{
                width: width_middle-20,
                // backgroundColor:"#00ffff",
                height: height,
                marginLeft: 20,
                paddingTop: 10,
                overflowX: "hidden",
                // overflowY: "auto",
                // WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                // WebkitTouchCallout: 'none',
                // WebkitUserSelect: 'none'
              }}
            >
              <div
                style={{
                  width: width_middle-20,
                  height: "auto",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  // WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                  // WebkitTouchCallout: 'none',
                  // WebkitUserSelect: 'none'
                }}
              >
               
                <div style={{height:50}}/>
                {this.props.history.map((item, index) => {
                  

                  var tokenInfo = "no records"
                  var functionCallInfo = ""
                  var referenceInfo = ""
                  var ifGraphRAG = false
                  var ifReference = false
                  
                  if(item.extra && item.extra != "0"){
                    var extraData = JSON.parse(item.extra);
                    // console.log(extraData)
                    if(extraData.prompt_tokens){
                      tokenInfo = "prompt_tokens:"+ extraData.prompt_tokens+"\n"
                    }
                    if(extraData.total_tokens){
                      tokenInfo = tokenInfo + "export_tokens:"+ (extraData.total_tokens - extraData.prompt_tokens)+"\n"
                    }
                    if(extraData.model){
                      tokenInfo = tokenInfo + "model:"+ extraData.model+"\n"
                    }
                    if(extraData.reference && extraData.reference.length != 0){
                      // const jsonObject = JSON.parse(extraData.reference);
                      // console.log(jsonObject) // {refercen:xxxxx,searchType:"vector"}
                      ifReference = true
                      for(var rItem in extraData.reference){
                        var refer =  extraData.reference[rItem]
                        var referObj =  JSON.parse(refer)
                        // console.log(referObj)
                        referenceInfo = referenceInfo + referObj.reference
                        // if(referObj.searchType == "word"){
                          
                        // }
                        // if(referObj.searchType == "graphRAG"){
                        //   ifGraphRAG = true
                        //   for(var ii in referObj.reference){
                        //     referenceInfo = referObj.reference
                        //   }
                        // }else{
                        //   for(var ii in referObj.reference){
                        //     referenceInfo = referenceInfo + referObj.reference[ii].page + "   "
                        //   }
                        // }
                        // console.log(referObj.paeg)
                        
                      }
                    }
                    if(extraData.functionCall){
                      if(extraData.functionCall.length != 0){
                        for(var rItem in extraData.functionCall){

                          var refer =  extraData.functionCall[rItem]
                          // var referObj =  JSON.parse(refer)
                          console.log(refer)
                          functionCallInfo = functionCallInfo + "使用した関数：" + refer.function_name + "\n入力値：" + refer.function_args.input + ". \n"
                          
                        }
                      }

                    }
                  }
                  console.log(referenceInfo)


                  var ifIsGpt = false;
                  var username = "user";

                  var date = ""
                  if(item.time){
                    date = formatHourAndMinute(item.time);
                  }else{
                    date = "";
                  }
  
                  //console.log(item)
  
                  if (item.role == "assistant") {
                    ifIsGpt = true;
  
                    // var gptInfo = this.state.gptList_dic[item.userid];
                    //console.log(gptInfo)
  
                    try {
                      var username = this.props.gptData.gptName;
                      var icon_gpt = this.props.gptData.gptIcon;
                    } catch (error) {
                      var username = "untitled agent"
                      var icon_gpt = gpticon;
                    }
                    // if (icon_gpt == 0) {
                    //   icon_gpt = gpticon;
                    // }
                  }
  
                  let displayDate = false;
                  let StringDate = formatDate_tellDate(item.time);
  
                  // ---------------------------------------------------
                  if (index > 0) {
                    // console.log(this.props.history[index - 1])
                    let prevDate = formatDate_tellDay(
                      this.props.history[index - 1].time
                    );
                    let currDate = formatDate_tellDay(item.time);
                    displayDate = prevDate != currDate;
                    // const codeBlockRegex = /```\s*([\s\S]*?)\s*```/g;
                    // const contentParts = item.content.split(codeBlockRegex);
                    var content = item.content.split("```");
                    //console.log(content.length)
                    var ifHasCode = false;
                    var content_hasCode = [];
                    if (content.length > 1) {
                      //console.log("hahacode")
                      ifHasCode = true;
                      for (var i = 0; i < content.length; i++) {
                        content_hasCode.push({
                          key: i + 1,
                          value: content[i],
                        });
                      }
                    }
                    // console.log(content_hasCode)
                  }
                  if (item.role != "system") {
                    return (
                      <div 
                        style={{overflowY:"hidden"}}
                        >
                        {displayDate ? (
                          <div
                            style={{
                              width: width_middle - 60,
                              marginTop: -20,
                              marginBottom: -12,
                            }}
                          >
                            <Divider style={{ color: "#d8d8d8" }}>
                              {StringDate}
                            </Divider>
                          </div>
                        ) : null}
                        <div style={{ height: 7 }} />
                        <div style={{ height: 1 }} />
                        <div>
                          <Space>
                            <div>
                              <div style={{ float: "left" }}>
                                {ifIsGpt ? (
                                  <div>
                                    <div
                                      style={{
                                        borderRadius: 20,
                                        overflow: "hidden",
                                      }}
                                    >
                                      {icon_gpt != 0 ? (
                                        <ImageComponent
                                          src={icon_gpt}
                                          width={40}
                                          height={40}
                                        />
                                      ):(
                                        <Avatar
                                          style={{
                                          backgroundColor:"#000000",
                                          width:40,height:40,
                                          }}
                                          icon={<div style = {{marginTop:5.7}}><AppstoreOutlined style={{fontSize:22}}/></div>}
                                          />	
                                      )}
                                      
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <Avatar
                                      style={{
                                        backgroundColor: item.iconColor,
                                        width: 40,
                                        height: 40,
                                        paddingTop: 8,
                                      }}
                                      icon={
                                        <UserOutlined style={{ fontSize: 23 }} />
                                      }
                                    />
                                    {/* <div style={{width:40,height:40,borderRadius:20,backgroundColor:item.iconColor}}>
                                    
                                  </div> */}
                                  </div>
                                )}
                              </div>
  
                              <div
                                style={{
                                  width: ifMobile ? width_middle - 90:width_middle - 110,
                                  borderRadius: "8px",
                                  backgroundColor:"#ffffff",
                                  height: "auto",
                                  paddingTop: "1px",
                                  // paddingBottom:"5px",
                                  paddingLeft: "15px",
                                  paddingRight: "0px",
                                  marginBottom: -10,
                                  //fontWeight:"bold"
                                  fontSize: "14px",
                                  float: "left",
                                  overflowY:"hidden"
                                }}
                              >
                                <div
                                  style={{
                                    hegiht: 10,
                                    marginBottom: -13,
                                    fontWeight: "bolder",
                                  }}
                                >
                                  <Space>
                                    <div>{username}</div>
                                    {ifIsGpt ? (
                                      <div
                                        style={{
                                          width: 31,
                                          height: 13,
                                          borderRadius: "4px",
                                          backgroundColor: "#f1f1f1",
                                          paddingLeft: 2,
                                          paddingTop: 1,
                                          color: "#a1a1a1",
                                          fontSize: 10,
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Agent
                                      </div>
                                    ) : (
                                      ""
                                    )}
  
                                    <div
                                      style={{
                                        color: "#d8d8d8",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {date}
                                    </div>
                                  </Space>
                                </div>
                                {/* <div 
                                // onClick = {()=>{
                                //   copy(item.content)
                                //   message.info("message copied")
                                // }}
                                style={{userSelect:"text"}}
                                >
                                  <ReactMarkdown>{item.content}</ReactMarkdown>
                                </div> */}
                                {ifHasCode ? (
                                  content_hasCode.map((item) => {
                                    if (item.key % 2 === 0) {
                                      return (
                                        <div
                                          style={{
                                            position: "relative",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            backgroundColor: "#23241F",
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            marginTop: 20,
                                            // userSelect:"text"
                                            
                                          }}
                                        >
                                          <Button
                                            type="ghost"
                                            style={{
                                              position: "absolute",
                                              right: 10,
                                              top: 10,
                                              color: "#ffffff",
                                            }}
                                            icon={<CopyOutlined />}
                                            onClick={() => {
                                              copy(item.value);
                                              message.success(
                                                "copied to clipboard~😌"
                                              );
                                            }}
                                          />
                                          <SyntaxHighlighter
                                            language="json"
                                            style={monokaiSublime}
                                            showLineNumbers={true} //这个是显示不显示左侧的行数
                                            //lineNumberStyle={{color: '#ddd', fontSize: 10}}     //这个是行数的样式
                                            wrapLines={true} //确定每行代码是否应该包装在父元素中
                                          >
                                            {item.value.trim()}
                                          </SyntaxHighlighter>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <ReactMarkdown 
                                          components={{
                                            img: ImageRenderer,
                                          }}
                                          style={{userSelect:"text"}}>{item.value}
                                        </ReactMarkdown>
                                      );
                                    }
                                  })
                                ) : isValidUrl(item.content) ? (
                                  // 如果是图片
                                  <img
                                    style={{ marginTop: 20, overflow: "hidden" }}
                                    width={width - 100}
                                    src={item.content}
                                  />
                                ) : (
                                  // 如果是普通文字
                                  <ReactMarkdown components={{
                                    img: ImageRenderer,
                                  }} style={{userSelect:"text"}}>{item.content}</ReactMarkdown>
                                )}
                                {item.role == "assistant" && (
                                  <Space 
                                    size="small"
                                    // style={{marginTop:-20}}
                                  > 
                                  {ifGraphRAG == false && (
                                     <Tooltip title={tokenInfo}>
                                      <Button style={{marginLeft:-5}} size="small"  type="text" icon = {<InfoCircleOutlined style={{fontSize:13,color:"#b3b3b3"}} />} />
                                    </Tooltip>
                                  )}
                                   
                                  {functionCallInfo != "" && ifGraphRAG == false && (
                                    <Tooltip title={functionCallInfo}>
                                      <Button style={{marginLeft:-5}} size="small"  type="text" icon = {<AppstoreOutlined style={{fontSize:13,color:"#b3b3b3"}} />} />
                                    </Tooltip>
                                  )}
                                  {/* {referenceInfo != "引用したページ：" && ifGraphRAG == false && (
                                    <Tooltip title={referenceInfo}>
                                      <Button 
                                      style={{marginLeft:-5}} size="small"  type="text" icon = {<FileTextOutlined 
                                      style={{fontSize:13,color:"#b3b3b3"}} />} 
                                      
                                      
                                      />
                                    </Tooltip>
                                  )} */}
                                  {/* {ifGraphRAG == true && (
                                    <Tooltip title={"詳細（なまデータ）を見る"}>
                                      <Button 
                                      style={{marginLeft:-5}} size="small"  type="text" icon = {<FileTextOutlined 
                                      style={{fontSize:13,color:"#b3b3b3"}} />} 
                                      onClick = {()=>{
                                        this.setState({
                                          ifShowReferenceDetail:true,
                                          referenceDetail:referenceInfo
                                        })
                                      }}
                                      
                                      />
                                    </Tooltip>
                                  )} */}

                                  {ifReference == true && (
                                    <Tooltip title={"詳細（なまデータ）を見る"}>
                                      <Button 
                                      style={{marginLeft:-5}} size="small"  type="text" icon = {<FileTextOutlined 
                                      style={{fontSize:13,color:"#b3b3b3"}} />} 
                                      onClick = {()=>{
                                        this.setState({
                                          ifShowReferenceDetail:true,
                                          referenceDetail:referenceInfo
                                        })
                                      }}
                                      
                                      />
                                    </Tooltip>
                                  )}
                                    
                                  </Space>
                                )}
                                
                                
                                
                              </div>
                            </div>
                          </Space>
                          <div style={{ height: 15 }} />
                          {/* <div style={{width:width_middle-60}}><Divider/></div> */}
                        </div>
                      </div>
                    );
                  }
                  //-------------------------------------------------
                  //pinMessage_info
                  //为系统消息的情况下
                  else {
                    return (
                      <SystemInfo
                        type={item.username}
                        message={item.content}
                        date={date}
                      />
                    );
                  }
                })}
                <div style={{height:20}}/>

                {this.props.history.length >= 2 && (
                  <Space style={{marginLeft:-10}}>
                    <Popconfirm
                      title="Delete Messages"
                      description="You sure?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={this.props.deleteMessage}
                    >
                      <Button type="link" danger icon={<DeleteOutlined />}>delete</Button>
                    </Popconfirm>
                    
                    {!tellUserToken() && (
                      <Button type="link" icon={<UserAddOutlined />} onClick = {()=>{this.props.showLogin()}}>login</Button>
                    )}
                  </Space>
                )}
                
  
               
                <div style={{ height: 10 }} />
  
                
                {/* ------------------------------------ */}
                {/* 结尾定位用标签 */}
                <div
                  ref={this.consoleEndRef}
                  className="console-end"
                  style={{ display: "block" }}
                ></div>
                {/* ------------------------------------ */}
  
                {/* <div style={{width:width_middle-60,marginTop:-20}}><Divider/></div> */}
  
                <div style={{ height: 150 }} />
              </div>
            </div>

            {/* // 输入栏-------------------------------------------------- */}
            <div
              style={{
                backgroundColor: "#ffffff",
                position: "fixed",
                right: 0,
                bottom: 0,
                width: width - width_leftBar,
                height:20,
                zIndex:0
                
              }}
            />
            {/* <div
              style={{
                backgroundColor: "#ffffff",
                position: "fixed",
                right: 15,
                bottom: 0,
                width: width - width_leftBar -35,
                height:95,
                borderRadius:"15px 15px 0 0",
                zIndex:0
              }}
            /> */}
            <div
              style={{
                WebkitBackdropFilter: "blur(10px)",
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(200, 200, 200, 0.2)",
                // backgroundColor: "#f1f1f1f1",
                // border:"1px solid black",
                position: "fixed",
                right: 0,
                bottom: 0,
                paddingLeft: 15,
                //paddingRight:20,
                width: width - width_leftBar-30 -15,
                height:80,
                borderRadius:15,
                marginRight:15,marginBottom:15,
                zIndex:999,
                
              }}
            >
              <div style={{ height: 15 }} />
              <Space>
                <TextArea
                  placeholder="What you want to say?"
                  style={{
                    width: width - width_leftBar - 120,
                    // backgroundColor:"#f1f1f1",
                    // borderRadius:15
                    
                  }}
                  value={this.state.inputValue}
                  onChange={(value) => {
                    this.setState({ inputValue: value });
                  }}
                  autoSize={{ minRows: 1, maxRows: 2 }}
                  ref={this.myRef}
                  
                />
  
                <Button
                  shape="circle"
                  // type="ghost"
                  type="primary"
                  style={{ height: 50, width: 50, fontWeight: "bold" }}
                  // onClick={this.postMessage}
                  onClick={(e)=>{
                    this.postMessage_callGpt(e)
                  }}
                  icon={<RightOutlined />}
                />
             
              </Space>
  
              <div style={{ height: 15 }} />
            </div>
            
            {/* <div
              // 输入栏--------------------------------------------------
              style={{
                width: width,
                height: 1,
                position: "fixed",
                left: width_leftBar,
                bottom: "104.5px",
              }}
            >
              <Divider />
            </div> */}
            
          </div>



          {/* pinDetailReference */}
          <Popup
            position={ifMobile? 'bottom':"right"}
            onMaskClick={() => {
              this.setState({ ifShowReferenceDetail: false });
            }}
            onClose={() => {
              this.setState({ ifShowReferenceDetail: false });
            }}
            // showCloseButton
            visible={this.state.ifShowReferenceDetail}
            bodyStyle={{
              height:ifMobile? height/2:height,
						  width:ifMobile? width:500
              // height: height,
              // width: width,
            }}
          >
            <div
              style={{
                height:ifMobile? height/2:height,
						    width:ifMobile? width:500,
                overflow: "hidden",
                overflowY:"scroll"
              }}
            >
              <div
                style={{
                  height: height,
                  float: "left",
                  padding: 20,
                  paddingTop: 15,
                }}
              >
                
                
                <h2>詳細（なまデータ）</h2>

                <ReactMarkdown components={{
      img: ImageRenderer,
    }} style={{userSelect:"text"}}>{this.state.referenceDetail}</ReactMarkdown>
                

            
                <div style={{ height: 10 }} />
  
                
                
              </div>
            </div>
          </Popup>
          


      </div>
    );
  }
}
