import React, { Component } from "react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';




const colorList = [
	"#FF7700",
	"#05C3FD",
	"#22CC14",
	"#FF0099",
	"#8A8A8A",
]



class ImagesList extends Component {

  //pinS
  state = {
    waveData:[
      { name: '3月', "リアクション": 4000, "回答数": 2400, amt: 2400 },
      { name: '4月', "リアクション": 3000, "回答数": 1398, amt: 2210 },
      { name: '5月', "リアクション": 2000, "回答数": 9800, amt: 2290 },
      { name: '6月', "リアクション": 2780, "回答数": 3908, amt: 2000 },
      { name: '7月', "リアクション": 1890, "回答数": 4800, amt: 2181 },
      { name: '8月', "リアクション": 2390, "回答数": 3800, amt: 2500 },
      { name: '9月', "リアクション": 3490, "回答数": 4300, amt: 2100 },
    ],
    tagsInUse:[
			"tag"
		],
  };

  render() {

    var width = this.props.width
    var height = this.props.height
    var ifMobile = height > width;

    return(
      <div
        style={{width:width,height:height,
          // backgroundColor:"#ff0000"
        }}
      >
        <LineChart
					style={{marginLeft:-50}}
					// onMouseMove = {this.handleMove}
					width={width}
					height={height}
					data={this.state.waveData}
					margin={{ top: 15, right: 0, left: 30, bottom: 0 }}
					>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis 
					// domain={[0, 40]} 
					/>
					<Tooltip />
					
					<Legend />
					{/* 增加 strokeWidth 属性来加粗线条 */}
          <Line type="monotone" dataKey="回答数" stroke="#8884d8" strokeWidth={2} activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="リアクション" stroke="#82ca9d" strokeWidth={2} />
				
				</LineChart>
      
      </div>
    )
  }
}

export default ImagesList;
