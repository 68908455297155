

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Button,Input,message,Segmented,Space} from 'antd';
import { Popup } from "antd-mobile";
import { env_serverAddress,domain} from "../../env";
import { loginUser, onLogin, tellUserToken, removeToken,ifHasToken } from "../../auth";
import { 
	MenuFoldOutlined,
	MenuOutlined,
	LockOutlined,
	PoweroffOutlined,
	UserOutlined,
	AppstoreOutlined,
	FileOutlined

} from '@ant-design/icons';

//-------------------------------
// import Compo_card_small from "./Compo_card_small"
import Page_myAgent from "../MyAgents"
import Page_myFiles from "../MyFiles"
// import Page_dashboard_app_gpttogether from "./Page_dashboard_app_gpttogether"


// import logo_uol from "./static/logo_uol.png"
// import png_app_gpttogether_beta from "./static/png_app_gpttogether_beta.png"
// import logo from "./static/logo_mtplus.png"
import logo_agentplus from "../../static/logo_agentplus.png";





// import { 
// 	CaretRightOutlined
// } from '@ant-design/icons';
// import Page_dashboard_setting_myProfile from "./Page_dashboard_setting_myProfile";




// const { Panel } = Collapse;



// const { Title, Paragraph, Text, Link } = Typography;




class page_dahsboard extends Component {


	
	//pinState
	state={
		currentPage:"agents",
		segmentOptions:[
			{
			  label: ' agents',
			  value: 'agents',
			  icon: <UserOutlined />,
			}
		],
		ifShowLeftBar:false,
		oldPassword:"",
		newPassword1:"",
		newPassword2:"",

	}

	componentDidMount(){
		console.log(this.props.userData)
		if(this.props.userData.ifPdf){
			this.setState({segmentOptions:
				[
					{
					  label: ' my agents',
					  value: 'agents',
					  icon: <UserOutlined />,
					},
					{
						label: ' my files (beta)',
						value: 'files',
						icon: <FileOutlined />,
					},
				]
			})
		}
	}

	//pinFetchUser
	changePassword = async () => {
		
		this.setState({ ifChangingPassword: true });
		if(this.state.oldPassword != "" &&  this.state.newPassword1 != "" && this.state.newPassword1 == this.state.newPassword2){
			let response = await fetch(env_serverAddress + "back/mygpt/changepassword", {
				method: "post",body: JSON.stringify({
				  userToken: tellUserToken(),
				  oldPassword:this.state.oldPassword,
				  newPassword1:this.state.newPassword1,
				  newPassword2:this.state.newPassword2,
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
				  "Content-Type": "application/json",
				},
			  });
			  let result = await response.json();
			  console.log(result);
			  if(result){
				if(result.statusCode == 1){
					message.success("password changed!")
					onLogin(result.returnData);
					this.setState({ifShowEditPassword:false})
				}
				if(result.statusCode == 4){
					message.error("old password is wrong")
				}
				if(result.statusCode == 9){
					message.error("new password contains illegal content")
				}
				
			  }
		}else{
			if(this.state.oldPassword == ""){
				message.error("please input your old password first")
			}
			if(this.state.newPassword1 != this.state.newPassword2){
				message.error("your new password is different")
			}
			if(this.state.newPassword1 == ""){
				message.error("please tell us your new password")
			}
		}


		this.setState({ ifChangingPassword: false });
		
	  };
	
	
	render() {

		//var height_memberBar = this.myRef_member_div.current.clientHeight

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		
		// var width_leftBar = 250
		// var width_middle = width - width_leftBar 
		var ifMobile = width < height;
		// if (ifMobile) {
		// 	width_leftBar = 0;
		// 	width_middle = width
		// }


		// pinReturn
		return(
		
			<div style={{width:width,height:height,position:"fixed",left:0,top:0}}
				>
				<div 
					style={{
					width:width,			
					backgroundColor:"#ffffff",
					height:height,
					float:"left",
					//padding:20,
					}}>

					{/* pinBar 顶部栏目 */}
					<div
						style={{
							width: width,
							backgroundColor: "#ffffff",
							height: 40,
							position: "absolute",
							top: 0,
						}}
						>
						<div
							style={{
							position: "absolute",
							left: 15,
							top: 10,
							width: 30,
							cursor: "pointer",
							}}
						>
							<MenuOutlined
							style={{ fontSize: 20}}
							onClick={() => {
								// this.props.callBack_closePage()
								this.setState({ifShowLeftMenu:true})
							}}
							/>
						</div>

						<Space>
							<div style={{ 
								width: 200, height: 40, 
								// backgroundColor: "#ff0000" ,
								// width: 90,
								marginLeft: width / 2 - 45,
								marginTop: 5,
								}}>
								<a  href={domain} target="_blank" rel="noopener noreferrer">
									<img
										style={{
											width: 90,
										}}
										src={logo_agentplus}
									/>
								</a>
							</div>
						</Space>

						

						<div
							style={{ width: width, height: 1, position: "absolute", top: 16 }}
						>
							<Divider />
						</div>
					</div>
					
					<div style={{height:55}}/>

					<Segmented
						style={{
							width:ifMobile? width-30:400,
							marginLeft:15,marginRight:15,
						}}
						options={this.state.segmentOptions}
						block
						onChange={(nextSegment) => {
						this.setState({ currentPage: nextSegment });
						}}
					/>

					<div style={{height:5}}/>
					{this.state.currentPage === "agents" && (
						<Page_myAgent 
							width={width}
							height={height-20}
							logOut={this.props.logOut}
							userData={this.props.userData}
						/>
					)}
					{this.state.currentPage === "files" && (
						<Page_myFiles 
							width={width}
							height={height-20}
							logOut={this.props.logOut}
							userData={this.props.userData}
						/>
					)}
				
				
				</div>


				{/* pinIfLogout */}
				<Popup
					position={ifMobile? 'bottom':"left"}
					onMaskClick={() => {
						this.setState({ifShowIfLogout:false})
					}}
					onClose={() => {
						this.setState({ifShowIfLogout:false})
					}}
					showCloseButton
					visible={this.state.ifShowIfLogout}
					bodyStyle={{
						height:ifMobile? 150:height,
						width:ifMobile? width:300
					}}
					>
					<div style={{
						height:ifMobile? 150:height,
						overflowX:"hidden",
						overflowY:"auto",
						width:ifMobile? width:500,
					// position:"relative"
					}}>
					
					<div
						style={{
						height:"auto",
						overflowX:"hidden",
						overflowY: 'scroll',
						// padding:20,
						marginLeft:20,
						//backgroundColor:"#79D760",
						width:ifMobile? width-40:260}}>

						<div style={{height:30}}/>
						<div style={{fontSize:"15px",fontWeight:"bold"}}>
											You sure?
						</div>


						<div style={{height:20}}/>
						<Button 
							block 
							danger
							onClick ={()=>{
								this.setState({ifShowIfLogout:false})
								this.setState({ifShowLeftMenu:false})
								this.props.logOut()
							}}
							// icon={<DeleteOutlined/>}
							type="primary" 
							style={{height:40}}>
							Yes
						</Button>
						<div style={{height:30}}/>
						



					</div>
					</div>
					

					
				</Popup>



				{/* pinLeftMenu */}
				<Popup
					position={ifMobile? 'bottom':"left"}
					onMaskClick={() => {
					this.setState({ ifShowLeftMenu: false });
					}}
					onClose={() => {
					this.setState({ ifShowLeftMenu: false });
					}}
					showCloseButton
					visible={this.state.ifShowLeftMenu}
					bodyStyle={{
						height:ifMobile? 200:height,
						width:ifMobile? width:300
					}}
				>
					<div style={{
						height:ifMobile? 200:height,
						overflowX:"hidden",
						overflowY:"auto",
						width:ifMobile? width:300,
					// position:"relative"
					}}>
					
						<div
							style={{
							height:"auto",
							overflowX:"hidden",
							overflowY: 'scroll',
							// padding:20,
							marginLeft:20,
							marginRight:20,
							//backgroundColor:"#79D760",
							width:ifMobile? width-40:260
							}}>
								<div style={{height:10}}/>
								<h2>Setting</h2>
								{/* <div style={{height:10}}/> */}
								<Button
									onClick={()=>{
										this.setState({ifShowEditPassword:true})
									}}
									size="large"
									// type="primary"
									block
									icon={<LockOutlined />}
									>
									change password
								</Button>
								<div style={{height:10}}/>
								<Button
									// onClick={this.open_ifShowLogin}
									size="large"
									type="primary"
									danger
									block
									icon={<PoweroffOutlined />}
									onClick = {()=>{
										this.setState({ifShowIfLogout:true})
									}}
									>
									logout
								</Button>
						</div>
					</div>
				</Popup>


				{/* pinEditPassword */}
				<Popup
					position={ifMobile? 'bottom':"left"}
					onMaskClick={() => {
						this.setState({ifShowEditPassword:false})
					}}
					onClose={() => {
						this.setState({ifShowEditPassword:false})
					}}
					showCloseButton
					visible={this.state.ifShowEditPassword}
					bodyStyle={{
						height:ifMobile? 350:height,
						width:ifMobile? width:300
					}}
					>
					<div style={{
						height:ifMobile? 350:height,
						width:ifMobile? width:500,
						overflowX:"hidden",
						overflowY:"auto",
						
					// position:"relative"
					}}>
					
					<div
						style={{
						height:"auto",
						overflowX:"hidden",
						overflowY: 'scroll',
						// padding:20,
						marginLeft:20,
						//backgroundColor:"#79D760",
						width:ifMobile? width-40:260}}>

						<div style={{height:10}}/>
						<h2>Change Password</h2>
						{/* <div style={{height:10}}/> */}
						
						{/* <h3>old Password</h3> */}
						{/* <div style={{height:10}}/> */}
						<Input
							placeholder='old password'
							prefix={<LockOutlined />}
							style={{
								width:ifMobile? width-40:260
							}}
							maxLength={50}
							value={this.state.currentPassword}
							onChange={(e)=>{
								this.setState({oldPassword:e.target.value})
							}}
							/>
						{/* <div style={{height:10}}/> */}
						<div style={{height:2}}><Divider/></div>
						{/* <h3>new Password</h3> */}
						<div style={{height:20}}/>
						<Input
							placeholder='your new password'
							prefix={<LockOutlined/>}
							style={{
								width:ifMobile? width-40:260
							}}
							maxLength={50}
							value={this.state.newPassword1}
							onChange={(e)=>{
								this.setState({newPassword1:e.target.value})
							}}
							/>
						<div style={{height:20}}/>
						{/* <h3>input again </h3> */}
						{/* <div style={{height:10}}/> */}
						<Input
							placeholder='please input again'
							prefix={<LockOutlined/>}
							style={{
								width:ifMobile? width-40:260
							}}
							maxLength={50}
							value={this.state.newPassword2}
							onChange={(e)=>{
								this.setState({newPassword2:e.target.value})
							}}
							/>
						<div style={{height:10}}/>

						<div style={{height:20}}/>
						<Button 
							block 
							disabled={this.state.ifChangingPassword}
							onClick ={()=>{this.changePassword()}}
							type="primary" 
							style={{height:40}}>
							Ok
						</Button>
						<div style={{height:30}}/>
						



					</div>
					</div>
					

					
				</Popup>

			</div>






			
			
			
			
		)

  	}
}

export default withRouter(page_dahsboard);