import { Avatar, List } from 'antd';
import emoji_paper from "../../../static/emoji_paper.png";
import React, { useEffect, useState } from 'react';
import {env_serverAddress,cookie_key,domain} from "../../../env"
import { Popup} from 'antd-mobile'
import { Input,Button,message,Space,Divider} from 'antd';
import { 
  CheckCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  FileImageOutlined,
  ArrowUpOutlined,
  EditOutlined,
  MessageOutlined,
  AppstoreOutlined
} from '@ant-design/icons';
import { LikeOutlined } from '@ant-design/icons';
import { Col, Row, Statistic } from 'antd';

import ImageComponent from "../../../component/ImageComponent"
import ImagesList from "./ImagesList"
import WaveGraph from "./WaveGraph"
import ReactMarkdown from "react-markdown";





const { TextArea } = Input;
const App = (props) => {

  const [surveys,setsurveys] = useState([])
  const [ifLoading,setIfLoading] = useState(false)
  const [currentsurvey,setCurrentsurvey] = useState({title:"xxx",content:"xxxx"})
  const [ifEditedsurvey,setIfEditedsurvey] = useState(false)
  const [ifUploadingImage,setIfUploadingImage] = useState(false)
  const [imagesUrlsTempOfNewsurvey,setImagesUrlsTempOfNewsurvey] = useState([
    // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBtB2jH-U9jfqBasrR2hTzNoGx-NviAOwz0Q&s",
    // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBtB2jH-U9jfqBasrR2hTzNoGx-NviAOwz0Q&s",
    // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBtB2jH-U9jfqBasrR2hTzNoGx-NviAOwz0Q&s"
  ])
  

  const [width,setWidth] = useState(0)
  const [height,setHeight] = useState(0)
  const [ifMobile,setIfMobile] = useState(false)
  const [ifShowEditsurvey,setIfShowEditsurvey] = useState(false)
  const [ifShowSurveyDetail,setIfShowSurveyDetail] = useState(false)

  const [ifShowImageCache,setIfShowImageCache] = useState(false)
  const [ifShowIfDelete,setIfShowIfDelete] = useState(false)
  const [ifInsertedImage,setIfInsertedImage] = useState(false)


  const InsertImageFromImagesUrlsTempOfNewsurvey = ()=>{
    console.log(imagesUrlsTempOfNewsurvey)
    var oldContent = currentsurvey.content

    for (var i in imagesUrlsTempOfNewsurvey){
      oldContent += "![名無し画像](" + imagesUrlsTempOfNewsurvey[i] + ")\n\n"
    }
    setCurrentsurvey({
      title:currentsurvey.title,
      content:oldContent  , 
      id:currentsurvey.id,
    })
    setIfEditedsurvey(true)
    setIfInsertedImage(true)
    setIfShowImageCache(false)

    // setImagesUrlsTempOfNewsurvey([])
  }

  const uploadPostImage = async (e) => {
    if (!ifUploadingImage) {
      message.info("uploading, please wait");
      try {
        //console.log(this.state.gptid_current)
        console.log("uploadPostImage-------1")
        e.preventDefault();
        var url = env_serverAddress + "back/mygpt/uploadpostimage";
        let file = e.target.files[0];
        const formdata = new FormData();
        setIfUploadingImage(true)
        console.log("uploadPostImage-------2")
        var info = JSON.stringify({
          token: props.user_token,
        });
        formdata.append("file", file);
        formdata.append("info", info);

        let response = await fetch(url, {
          method: "post",
          body: formdata,
          // headers: { "Content-Type": "multipart/form-data" },
        });
        const data = await response.json();
        fetchPostImageTempUrls();
        setIfUploadingImage(false)

      } catch (error) {
        // console.log(error)
        // message.error("upload failed");
        fetchPostImageTempUrls();
        setIfUploadingImage(false)
      }
    } else {
      message.info("please wait");
    }
  };


  const clearPostImage = async () => {
    try {
      var url = env_serverAddress + "back/mygpt/clearpostimagetempurl";
      let response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          token: props.user_token,
        }),
        headers: { "Content-Type": "application/json" },
      });
      let data = await response.json();
      console.log(data)
     
    } catch (error) {
      console.log("error in clearPostImage");
    }
  };

  const removePostImage = async (imageUrl) => {
    try {
      var url = env_serverAddress + "back/mygpt/removepostimagetempurl";
      let response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          token: props.user_token,
          url: imageUrl,
        }),
        headers: { "Content-Type": "application/json" },
      });
      let data = await response.json();
      console.log(data);
      if (data) {
        fetchPostImageTempUrls();
      }
    } catch (error) {
      console.log("error in fetchShareData");
    }
  };


  const fetchPostImageTempUrls = async () => {
    try {
      var url = env_serverAddress + "back/mygpt/fetchpostimagetempurls";
      let response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          token: props.user_token,
        }),
        headers: { "Content-Type": "application/json" },
      });
      let data = await response.json();
      console.log(data);
      if (data) {
        if (data.statusCode == 1) {
          // 获取到最新的数据啦
          if (data.returnData.urls) {
            setImagesUrlsTempOfNewsurvey(data.returnData.urls)
            
          }
        }
      }
    } catch (error) {
      console.log("error in fetchShareData");
    }
  };





  const fetchGPTsurvey = async(gptid)=>{
    setIfLoading(true)
		try{
			var url = env_serverAddress+'back/mygpt/fetchgptsurvey'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					token:props.user_token,
					gptid:props.gptid,
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json().then()
			console.log(data)
			if(data.statusCode != 0){
        console.log(data.returnData.survey)
        setsurveys(data.returnData.survey)
			}
		}
		catch(error){
		}
    setIfLoading(false)
	}

  const addsurvey = async()=>{
    setIfLoading(true)
		try{
			var url = env_serverAddress+'back/mygpt/addsurvey'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					token:props.user_token,
					gptid:props.gptid,
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json().then()
			console.log(data)
			if(data.statusCode != 0){
        message.success('new survey added!　😆');
        fetchGPTsurvey()
			}
		}
		catch(error){
		}
    setIfLoading(false)
	}

  useEffect(() => {

    setWidth(document.documentElement.clientWidth)
    setHeight(document.documentElement.clientHeight)
    setIfMobile(document.documentElement.clientWidth < document.documentElement.clientHeight)

    // console.log(width,height,ifMobile)
    // console.log(props.user_token)
    fetchGPTsurvey()
   
  }, [props]);


  const onChange_value = (e) =>{
    const newvalue = e.target.value
    setCurrentsurvey({
      id:currentsurvey.id,
      title:currentsurvey.title,
      content:newvalue
    })
    setIfEditedsurvey(true)
    console.log(e.target.value)
  }

  const onChange_title = (e) =>{
    const newvalue = e.target.value
    setCurrentsurvey({
      id:currentsurvey.id,
      title:newvalue,
      content:currentsurvey.content
    })
    setIfEditedsurvey(true)
    console.log(e.target.value)
  }

  const handleUpdate = async () =>{

    console.log(currentsurvey)
    setIfLoading(true)
		try{
			var url = env_serverAddress+'back/mygpt/editsurvey'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					token:props.user_token,
					gptid:props.gptid,
          newData:currentsurvey
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json().then()
			console.log(data)
			if(data.statusCode != 0){
        message.success('done!　😆');
        if(ifInsertedImage){
          clearPostImage()
        }
        fetchGPTsurvey()
        setIfShowEditsurvey(false)
        setIfShowSurveyDetail(false)
			}
		}
		catch(error){
      console.log(error)
		}
    setIfLoading(false)
  }



  const handleDel = async () =>{
    console.log(currentsurvey)
    setIfLoading(true)
		try{
			var url = env_serverAddress+'back/mygpt/delsurvey'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					token:props.user_token,
					gptid:props.gptid,
          surveyid:currentsurvey.id
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json().then()
			// console.log(data)
			if(data.statusCode != 0){
        message.success('done!　😆');
        fetchGPTsurvey()
        setIfShowEditsurvey(false)
			}
		}
		catch(error){
		}
    setIfLoading(false)
  }

  return(

    <div>
      {ifLoading?(
        <LoadingOutlined style={{fontSize:20}}/>
  
      ):(
        <div>

          {surveys.length !=0 ? (
            <List
              style={{cursor:"pointer"}}
              itemLayout="horizontal"
              dataSource={surveys}
              renderItem={(item, index) => (
                <List.Item style={{marginLeft:-25}}
                  actions={[
                    <Space>
                      {/* <Button type="text" style={{marginLeft:-20,marginRight:-10}} icon={<LinkOutlined/>}>20</Button> */}
                      <Button type="text" style={{marginLeft:-40,marginRight:-30}} icon={<MessageOutlined style={{color:"#3875F6",fontWeight:"bold"}} />}>20</Button>
                    </Space>,
                    ]}
                >
                  
                  <List.Item.Meta
                    onClick={()=>{
                      setIfEditedsurvey(false)
                      setCurrentsurvey(item)
                      fetchPostImageTempUrls()
                      setIfShowSurveyDetail(true)
                      
                      
                    }}
                    avatar={
                    <div style={{marginTop:19,width:40,height:40}} >
                      <ImageComponent src={emoji_paper} width={40} height={40}/>
                    </div>}
                    title={<div>{item.title == "0"? ("Tap to edit"):(item.title)}</div>}
                    description={item.content == "0"? ("unused"):(item.content.substring(0, 20) + "...")}
                  />
                </List.Item>
              )}
            />
          ):("")}

          

          <Button block 
            onClick ={addsurvey}
            icon={<PlusOutlined/>}
            type="ghost" 
            style={{height:40,marginTop:20,backgroundColor:"#f1f1f1"}}/>

        </div>


      )}
      
      


      


      {/* pinsurveyDetail */}
      <Popup
        position={ifMobile? 'bottom':"right"}
        onMaskClick={() => {
          setIfShowSurveyDetail(false)
          }}
        onClose={() => {
          setIfShowSurveyDetail(false)
        }}
        showCloseButton
        visible={ifShowSurveyDetail}
        bodyStyle={{
        height:ifMobile? height*0.80:height,
        width:ifMobile? width:500
        }}
        >
        <div style={{
          height:ifMobile? height*0.80:height,
          overflowX:"hidden",
          overflowY:"auto",
          width:ifMobile? width:500,
          // position:"relative"
          }}>

          <div
            style={{
              height:"auto",
              overflowX:"hidden",
              overflowY: 'scroll',
              // padding:20,
              marginLeft:20,
              //backgroundColor:"#79D760",
              width:ifMobile? width-40:460,
              
              }}>

            <div style={{height:50}}/>


            <div style={{
              // backgroundColor:"#f1f1f1",
              // borderRadius:15,
              padding:0,
              height: "auto",
              // position:"relative"
              }}>
              <div style={{height:10}}/>

              <div 
                // onClick={this.handleClick_pullup_input_uploadImage_icon}
                style={{
                  width:60,
                  height:60,
                  overflow:"hidden",
                  borderRadius:30,
                  backgroundColor:"#f1f1f1",
                  float:"left"
                
                }}>
                {props.gptIcon_current=="0" ?(
                  <Avatar
                    style={{
                    backgroundColor:"#000000",
                    width:60,height:60,
                    }}
                    icon={<div style = {{marginTop:14}}><AppstoreOutlined style={{fontSize:30}}/></div>}
                    />	
                ):(
                  <ImageComponent 
                  src = {props.gptIcon_current}
                  height={60}
                  width={60}/>	
                )}
                
                  
              </div>
              
              <div style={{
                // height:60,
                height: "auto",
                float:"left",
                width:ifMobile? width-120:380,marginLeft:15,
                //backgroundColor:"#ff0000",
                overflow:"hidden"
                }}>

                {/* <div style={{height:7}} /> */}
                <div style={{fontSize:18,fontWeight:"bold",overflow:"hidden"}}>
                  {props.gptName_current}
                </div>
                <ReactMarkdown style={{userSelect:"text"}}>
                  {currentsurvey.content}
                </ReactMarkdown>
                
                
                <Button 
                  // block 
                  // disabled={!ifEditedsurvey}
                  onClick ={()=>{
                    setIfShowEditsurvey(true)
                    setIfShowSurveyDetail(false)
                  }}
                  icon={<EditOutlined/>}
                  type="link" 
                  style={{
                    // height:40,
                    // position:"absolute",right:10,top:24
                    marginLeft:-15,
                  }}>
                  Edit
                </Button>
                <div style={{height:20}}/>
              </div>

              
             
              
            {/* pin1 */}

            </div>
            <div style={{height:30}}/>
            <div style={{width:ifMobile? width-40:460,marginTop:30}}><Divider/></div>
                  
            <div style={{fontSize:"15px",fontWeight:"bold"}}>
              全体
            </div>
            <div style={{height:10}}/>
            <div style={{
              backgroundColor:"#f1f1f1",
              borderRadius:15,
              padding:20,
              height: "auto",
              position:"relative",
              display: 'flex',
              justifyContent: 'center',  // 水平居中
              alignItems: 'center',      // 垂直居中
              }}>
              <div style={{height:10}}/>
              {/* <div style={{width:100,height:70,backgroundColor:"#ff00ff"}}></div> */}

              <Space>
                <div span={12}>
                  <Statistic title="回答数" value={1128} 
                  // prefix={<MessageOutlined />} 
                  />
                </div>
                <div style={{width:20}}/>
                <div span={12}>
                  <Statistic title="リアクション" 
                    valueStyle={{
                      color: '#1EE486',
                    }}
                    value={"やや好評"} 
                    // prefix={<LikeOutlined />} 
                    />
                </div>
              </Space>

              <div style={{height:10}}/>
            </div>

            <div style={{height:20}}/>

            <div style={{fontSize:"15px",fontWeight:"bold"}}>
              リアクションの要約
            </div>
            <div style={{height:10}}/>

            <div style={{
              backgroundColor:"#f1f1f1",
              borderRadius:15,
              padding:20,
              height: "auto",
              position:"relative",
              display: 'flex',
              justifyContent: 'center',  // 水平居中
              alignItems: 'center',      // 垂直居中
              }}>
              <div style={{height:10}}/>
              <ReactMarkdown style={{userSelect:"text"}}>
                {"全体が満足が、**OOOO**と**OOOO**について**OOOO**、また**OOOO**もよく指摘されています。"}
              </ReactMarkdown>
              <div style={{height:10}}/>
            </div>
            
            <div style={{height:20}}/>

            <div style={{fontSize:"15px",fontWeight:"bold"}}>
              回答数推移
            </div>
            <div style={{height:10}}/>
            <div style={{
              backgroundColor:"#f1f1f1",
              borderRadius:15,
              padding:20,
              height: "auto",
              position:"relative",
              display: 'flex',
              justifyContent: 'center',  // 水平居中
              alignItems: 'center',      // 垂直居中
              }}>
              <div style={{height:10}}/>
              <WaveGraph width={ifMobile? width-40:460} height={200}/>



              <div style={{height:10}}/>
            </div>
            
            <div style={{height:10}}/>
            <div style={{width:ifMobile? width-40:460,marginTop:30}}><Divider/></div>
                 
            {/* <div style={{height:30}}/> */}
            <div style={{fontSize:"15px",fontWeight:"bold"}}>
              回答詳細
            </div>
            <div style={{height:10}}/>
            <Button type="primary" block 
              icon={<MessageOutlined />}
              // onClick = {()=>{
              //   InsertImageFromImagesUrlsTempOfNewsurvey()
              // }}
            >回答詳細（匿名）を見る</Button>

            <div style={{height:50}}/>
            

            
            
            

            
            



          </div>
        </div>
        

        
      </Popup>

      {/* pinsurvey */}
      <Popup
        position={ifMobile? 'bottom':"right"}
        onMaskClick={() => {
          setIfShowEditsurvey(false)
          }}
        onClose={() => {
          setIfShowEditsurvey(false)
        }}
        showCloseButton
        visible={ifShowEditsurvey}
        bodyStyle={{
        height:ifMobile? height*0.80:height,
        width:ifMobile? width:500
        }}
        >
        <div style={{
          height:ifMobile? height*0.80:height,
          overflowX:"hidden",
          overflowY:"auto",
          width:ifMobile? width:500,
          // position:"relative"
          }}>
          
          <div
            style={{
              height:"auto",
              overflowX:"hidden",
              overflowY: 'scroll',
              // padding:20,
              marginLeft:20,
              //backgroundColor:"#79D760",
              width:ifMobile? width-40:460}}>

            <div style={{height:30}}/>
            <div style={{fontSize:"15px",fontWeight:"bold"}}>
								Title
            </div>
            <div style={{height:10}}/>
            <Input
              placeholder='Please enter content'
              // prefix={<UserOutlined/>}
              style={{
                width:ifMobile? width-40:460,

              }}
              maxLength={50}
              value={currentsurvey.title}
              onChange={onChange_title}

              />

            <div style={{height:30}}/>
            <div style={{fontSize:"15px",fontWeight:"bold"}}>
              Value
            </div>
            <div style={{height:10}}/>
      
            <TextArea
              placeholder='Please enter content'
              style={{
                width:ifMobile? width-40:460,
              }}
              maxLength={200}
              showCount
              value={currentsurvey.content}
              onChange={onChange_value}
              autoSize={{ minRows: 4, maxRows: 8 }}
              />

          


            <div style={{height:10}}/>

            {ifShowImageCache ? (
              <div>
                <div style={{height:20}}/>
                <div style={{fontSize:"15px",fontWeight:"bold"}}>
                  Image Upload Cache
                </div>
                <div style={{height:10}}/>
                
                <div style={{
                  width:ifMobile? width-80:420,
                  padding:20,paddingBottom:20,
                  backgroundColor:"#f1f1f1",
                  borderRadius:15
                  }}>
                  {imagesUrlsTempOfNewsurvey.length != 0 && (
                    <div>
                      <Button type="primary" block icon={<ArrowUpOutlined />}
                        onClick = {()=>{
                          InsertImageFromImagesUrlsTempOfNewsurvey()
                        }}
                      >Insert</Button>
                      <div style={{height:20}}/>
                    </div>
                    
                  )}
                  
                  
                  <ImagesList
                    urls={imagesUrlsTempOfNewsurvey}
                    width={ifMobile ? width - 90 : 410}
                    removePostImage={removePostImage}
                    uploadPostImage={uploadPostImage}
                    ifShowImageViewer={false}
                  />

                </div>
              </div>
            ):(
              <Space>
                <Button  
                  // disabled={ifUploadingImage}
                  onClick ={()=>{
                    setIfShowImageCache(true)
                  }}
                  icon={<FileImageOutlined/>}
                  >
                  Image
                </Button>
              </Space>
            )}
            
            

            
            
            <div style={{height:40}}/>
            <Button block 
              disabled={!ifEditedsurvey}
              onClick ={handleUpdate}
              icon={<CheckCircleOutlined/>}
              type="primary" style={{height:40}}>
              Update
              </Button>

            <div style={{height:20}}/>
            <Button 
              block 
              danger
              onClick ={()=>{setIfShowIfDelete(true)}}
              icon={<DeleteOutlined/>}
              // type="text" 
              style={{height:40}}>
              Delete
            </Button>
            <div style={{height:20}}/>
            



          </div>
        </div>
        

        
      </Popup>

      {/* ifDelete */}
      <Popup
        position={ifMobile? 'bottom':"right"}
        onMaskClick={() => {
          setIfShowIfDelete(false)
          }}
        onClose={() => {
          setIfShowIfDelete(false)
        }}
        showCloseButton
        visible={ifShowIfDelete}
        bodyStyle={{
          height:ifMobile? 150:height,
        width:ifMobile? width:500
        }}
        >
        <div style={{
          height:ifMobile? 150:height,
          overflowX:"hidden",
          overflowY:"auto",
          width:ifMobile? width:500,
          // position:"relative"
          }}>
          
          <div
            style={{
              height:"auto",
              overflowX:"hidden",
              overflowY: 'scroll',
              // padding:20,
              marginLeft:20,
              //backgroundColor:"#79D760",
              width:ifMobile? width-40:460}}>

            <div style={{height:30}}/>
            <div style={{fontSize:"15px",fontWeight:"bold"}}>
								You sure?
            </div>


            <div style={{height:20}}/>
            <Button 
              block 
              danger
              onClick ={()=>{
                handleDel()
                setIfShowIfDelete(false)
              }}
              icon={<DeleteOutlined/>}
              type="primary" 
              style={{height:40}}>
              Delete
            </Button>
            <div style={{height:30}}/>
            



          </div>
        </div>
        

        
      </Popup>



      
    </div>
    

  )
  
};
export default App;