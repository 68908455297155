

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Button,Input,message} from 'antd';
import { Popup } from "antd-mobile";
import { Space} from 'antd';
import { env_serverAddress,domain} from "../../env";
import { loginUser, onLogin, tellUserToken, removeToken,ifHasToken } from "../../auth";
import { 
	MenuFoldOutlined,
	// LoadingOutlined, 
	// LeftOutlined, 
	// SettingOutlined,
	MenuOutlined,
	LockOutlined,
	PoweroffOutlined,
	UserOutlined

} from '@ant-design/icons';


import logo_agentplus from "../../static/logo_agentplus.png";




class page_dahsboard extends Component {


	
	//pinState
	state={
		ifResetPasswordSucceed:false,

	}

	//pinFetchUser
	resetPassword = async () => {
		
		this.setState({ ifResettingPassword: true });
		if(this.state.newPassword1 != "" && this.state.newPassword1 == this.state.newPassword2){
			let response = await fetch(env_serverAddress + "back/mygpt/resetpassword", {
				method: "post",body: JSON.stringify({
					resetPasswordToken: this.props.resetPasswordToken,
				  	newPassword1:this.state.newPassword1,
				  	newPassword2:this.state.newPassword2,
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
				  "Content-Type": "application/json",
				},
			  });
			  let result = await response.json();
			  console.log(result);
			  if(result){
				if(result.statusCode == 1){
					message.success("password changed!")
					this.setState({ifResetPasswordSucceed:true})
				}
				if(result.statusCode == 4){
					message.error("url expired 😢")
				}
				if(result.statusCode == 9){
					message.error("new password contains illegal content")
				}
				
			  }
		}else{
			if(this.state.newPassword1 != this.state.newPassword2){
				message.error("your new password is different")
			}
			if(this.state.newPassword1 == ""){
				message.error("please tell us your new password")
			}
		}
		this.setState({ ifResettingPassword: false });
		
	  };
	


	
	
	render() {

		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var ifMobile = width < height;
		

		// pinReturn
		return(
		
			<div style={{width:width,height:height,position:"fixed",left:0,top:0}}
				>
				<div 
					style={{
					// width:width/2,			
					// backgroundColor:"#ffffff",
					height:height,
					float:"left",
					//padding:20,
					marginLeft:20,
					}}>

					<div style={{height:ifMobile? 10:height/3}}/>

					{this.state.ifResetPasswordSucceed ? (
						<div>
							<h1>Password change!🎉🎉</h1>
							<div style={{height:2}}><Divider/></div>
							<div style={{height:20}}/>
							<a href={domain} 
								// target="_blank" 
								rel="noopener noreferrer">
								<Button 
									block 
									// disabled={this.state.ifChangingPassword}
									// onClick ={()=>{this.resetPassword()}}
									type="primary" 
									style={{height:40}}>
									Home page
								</Button>
							</a>
							

						</div>
					):(
						<div>
							<h1>Reset Password</h1>
							<p>please set a new password here</p>

							
							<div style={{height:2}}><Divider/></div>
							{/* <h3>new Password</h3> */}
							<div style={{height:20}}/>
							<Input.Password
								placeholder='your new password'
								prefix={<LockOutlined/>}
								style={{
									width:ifMobile? width-40:260
								}}
								maxLength={50}
								value={this.state.newPassword1}
								onChange={(e)=>{
									this.setState({newPassword1:e.target.value})
								}}
								/>
							<div style={{height:20}}/>
							{/* <h3>input again </h3> */}
							{/* <div style={{height:10}}/> */}
							<Input.Password
								placeholder='please input again'
								prefix={<LockOutlined/>}
								style={{
									width:ifMobile? width-40:260
								}}
								maxLength={50}
								value={this.state.newPassword2}
								onChange={(e)=>{
									this.setState({newPassword2:e.target.value})
								}}
								/>
							<div style={{height:10}}/>

							<div style={{height:20}}/>
							<Button 
								block 
								disabled={this.state.ifChangingPassword}
								onClick ={()=>{this.resetPassword()}}
								type="primary" 
								style={{height:40}}>
								Ok
							</Button>
							<div style={{height:30}}/>
						</div>
					)}

					

					{/* pin1 -------------------------------- */}
				
				</div>



			</div>






			
			
			
			
		)

  	}
}

export default withRouter(page_dahsboard);