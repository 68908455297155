//引入react核心库
import React from 'react'
//引入ReactDOM
import ReactDOM from 'react-dom'
//
import {BrowserRouter} from 'react-router-dom'
//引入App
import App from './App'
// import App_mobile from './App_mobile'
// import './index.css';

//import Login from './Login'

var width = document.documentElement.clientWidth
var height = document.documentElement.clientHeight
var ifMobile = (width < height)

ReactDOM.render(


		// ifMobile ? (
		// 	<BrowserRouter>
		// 		<App_mobile/>
				
		// 	</BrowserRouter>
		// ):(
		// 	<BrowserRouter>
		// 		<App/>
				
		// 	</BrowserRouter>
			
		// )
	
		<BrowserRouter>
			<App/>
			
		</BrowserRouter>
	,
	document.getElementById('root')
)