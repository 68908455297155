
import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
import copy from "copy-to-clipboard"
import { Divider,Typography,Skeleton,Button ,message,Avatar,Input,Switch} from 'antd';
import { 
	GlobalOutlined,
	LoadingOutlined,
	TeamOutlined,
	UserOutlined,
	EyeOutlined,
	CloseOutlined,
	AppstoreOutlined,
	CheckOutlined,
	CompassOutlined,
	EyeInvisibleOutlined, 
	CodepenOutlined,
	CloudUploadOutlined,
	PlusOutlined,
	LinkOutlined,
	CopyOutlined
} from '@ant-design/icons';

import { Popup} from 'antd-mobile'
import { Space ,Dropdown} from 'antd';
import ImageComponent from "../../component/ImageComponent"
import {env_serverAddress,domain} from "../../env"
// import {cookie_key} from "../../auth"
import { loginUser, onLogin, tellUserToken, removeToken,ifHasToken } from "../../auth"



import GPTList from "./component/GPTList"
import Notebook from "./component/Notebook"
import Reference from "./component/Reference"
import Survey from "./component/Survey"
import DevRAG from "./component/Dev"

import emoji_notebook from "../../static/emoji_notebook.png";
import emoji_books from "../../static/emoji_books.png";
import emoji_paper from "../../static/emoji_paper.png";
import emoji_kiui from "./static/emoji_kiui.png";

// import emoji_kiui from "../../static/emoji_kiui.png";
// import emoji_repeat from "../../static/emoji_repeat.png";


const { TextArea } = Input;
// const history = useHistory();

class App extends Component {

	constructor(props){
        super(props);
        this.myRef_input_uploadImage_banner=React.createRef();
		this.myRef_input_uploadImage_icon=React.createRef();

	}
	
	//pinState
	state={
		user_token:0,
		//switch_ifSureLeaveroom:false,
		list_robots:[],
		ifLoadingData:false,
		ifFinishedFirstFetch:false,
		ifShowEditGpt:false,
		ifShowEditNote:false,
		ifShowSetPassword:false,
		ifChangedRobotSetting:false,
		ifUpdatingRobotSetting:false,
		ifUploading:false,
		ifShowIfLogout:false,
		gpt_selected:0,

		message_deleteGpt:"Delete",
		message_deleteGpt_forMakeSure:"Sure?",
		embeddingOriginal:"",
		temp_embeddingUrlCreated:""
	}

	changeModle = (value) =>{
		this.setState({gptModel:value,ifChangedRobotSetting:true})
		console.log(value)

	}
	
	deleteGpt = () =>{
		var message_forMakeSure = this.state.message_deleteGpt_forMakeSure
		if(this.state.message_deleteGpt != message_forMakeSure){
			this.setState({message_deleteGpt:message_forMakeSure})
			setTimeout(()=>{
				this.setState({message_deleteGpt:"Delete"})
			},2000); 
		}
		else{
			alert("工事中です!!!")
		}	
	}
	handleClick_pullup_input_uploadImage_banner = () => {
		//console.log("haha")
		this.myRef_input_uploadImage_banner.current.click();
	}
	handleClick_pullup_input_uploadImage_icon = () => {
		//console.log("haha")
		this.myRef_input_uploadImage_icon.current.click();
	}
	open_ifShowEditGpt =()=>{this.setState({ifShowEditGpt:true,ifChangedRobotSetting:false})}
	close_ifShowEditGpt =()=>{this.setState({ifShowEditGpt:false})}

	onChange_embeddingOriginal =(input_text)=>{this.setState({embeddingOriginal:input_text.target.value})}
	onChange_gptName =(input_text)=>{this.setState({gptName_current:input_text.target.value,ifChangedRobotSetting:true})}
	onChange_gptHello =(input_text)=>{this.setState({gptHello_current:input_text.target.value,ifChangedRobotSetting:true})}
	onChange_gptModel =(input_text)=>{this.setState({gptModel_current:input_text.target.value,ifChangedRobotSetting:true})}
	onChange_gptDescription=(input_text)=>{this.setState({gptDescription_current:input_text.target.value,ifChangedRobotSetting:true})}
	onChange_preset_system =(input_text)=>{this.setState({preset_system_current:input_text.target.value,ifChangedRobotSetting:true})}
	// onChange_preset_user =(input_text)=>{this.setState({preset_user_current:input_text.target.value,ifChangedRobotSetting:true})}
	// onChange_preset_assistant =(input_text)=>{this.setState({preset_assistant_current:input_text,ifChangedRobotSetting:true})}
	onChange_password =(input_text)=>{this.setState({ifPassword_current:input_text.target.value,ifChangedRobotSetting:true})}





	requireGptToken = async(gptid)=>{
		try{
			var url = env_serverAddress+'back/mygpt/requiregpttoken'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					token:this.state.user_token,
					gptid:gptid,
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json().then()
			console.log(data)
			if(data != 0){
				const gptUrl = ""+domain +"?id="+ data.returnData.gpt_token
				// window.open(""+domain +"?id="+ data.returnData.gpt_token, '_blank');
				// copy(""+domain +"?id="+ data.returnData.gpt_token)
				// this.props.history.push(""+domain +"?id="+ data.returnData.gpt_token);
				
				this.setState({
					ifShowGptUrl:true,
					gptUrl:gptUrl,
					gptToken:data.returnData.gpt_token
				})
			}
			
			
		}
		catch(error){
		}
	}


	
	updateGptSetting = async()=>{
		try{
			this.setState({ifUpdatingRobotSetting:true})
			var url = env_serverAddress+'back/mygpt/updategptsetting'
			let response= await fetch(url,{
				method: 'post',
				body: JSON.stringify({
					model:this.state.gptModel_current,
					token:this.state.user_token,
					gptid:this.state.gptid_current,
					gptName_current:this.state.gptName_current,
					gptHello_current:this.state.gptHello_current,
					gptDescription_current:this.state.gptDescription_current,
					preset_system_current:this.state.preset_system_current,
					ifPublic:this.state.ifPublic_current,
					ifAnyone:this.state.ifAnyone_current,
					ifPassword:this.state.ifPassword_current,

					// preset_user_current:this.state.preset_user_current,
					// preset_assistant_current:this.state.preset_assistant_current,
				}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
				headers: {
					'Content-Type': 'application/json'
				}
			})
			let data = await response.json().then()
			console.log(data)
			if(data == 1){
				message.config({top: 3,duration: 2,});
				message.success('updated');
				this.setState({ifUploading:false});
				this.fetchGptListByOwnerid()
				this.close_ifShowEditGpt()
			}
			if(data == 0){
				message.config({top: 3,duration: 2,});
				message.error('update failed,check invaild character');
			}
			if(data == 3){
				message.config({top: 3,duration: 2,});
				message.error('invaild content');
			}
			if(data == 31){
				message.config({top: 3,duration: 2,});
				message.error('Model invaild');
			}
		}
		catch(error){
			message.config({top: 3,duration: 2,});
			message.error('update failed,check invaild character');
		}
		finally{
			this.setState({ifUpdatingRobotSetting:false})
		}
	}


	//pinFetch
	fetchGptListByOwnerid = async()=>{
		//尝试获取token
		if(!this.state.ifFinishedFirstFetch){
			this.setState({ifLoadingData:true})
		}
		console.log("fetchGptListByOwnerid----------------------------------------")
		var token_here = 0
		if(this.state.user_token == 0){
			try{
				token_here = tellUserToken()
				this.setState({user_token:token_here,ifLogined:true})
			}catch(error){
				this.setState({user_token:"NULL"})
				token_here = "NULL"
			}
		}
		else{
			//已经完成获取的话
			token_here = this.state.user_token
		}
		if(token_here != 0 && token_here != "NULL"){

			try{
				var url = env_serverAddress+'back/mygpt/fetchgptlist'
				let response= await fetch(url,{
					method: 'post',
					body: JSON.stringify({
						token:token_here,
					}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
					headers: {
						'Content-Type': 'application/json'
					}
				})
				let data = await response.json().then()
				
				var gptDataList = data.returnData
				// console.log(gptDataList)
				this.setState({ifUploading:false})
				var list_robots_new = []
				for(var i in gptDataList){
					var gptid = i
					var gptData = gptDataList[i]
					var model = gptData.model
					var gptName = gptData.gptname
					var gptHello = gptData.gpthello
					var gptDescription = gptData.gptdescription
					var icon = gptData.icon
					var banner = gptData.banner
					var preset_system = gptData.preset_system
					var ifPublic = gptData.if_public
					var ifAnyone = gptData.if_anyone
					var ifPassword = gptData.if_password
					// var preset_user = gptData.preset_user
					// var preset_assistant = gptData.preset_assistant
					// console.log("gptName,gptDescription,icon")
					// console.log(gptName,gptDescription,icon)
					list_robots_new.push({
						model:model,
						gptid:gptid,
						gptName:gptName,
						gptHello:gptHello,
						gptDescription:gptDescription,
						icon:icon,
						banner:banner,
						preset_system:preset_system,
						ifPublic:ifPublic,
						ifAnyone:ifAnyone,
						ifPassword:ifPassword
						// preset_user:preset_user,
						// preset_assistant:preset_assistant	
					})
					this.setState({
						list_robots:list_robots_new,
						ifFinishedFirstFetch:true,
					})

				}
				//{name:"3",icon:"",description:"test",banner:""},	



				//console.log(gptDataList)
				
			}
			catch(error){
				// this.setState({ifFetchingData:false})
				// this.setState({ifAllowLogin:true})
			}
		}
		this.setState({ifLoadingData:false})
		this.setState({ifUploading:false})


		
	}
	componentDidMount(){
		console.clear()
		//this.setState({list_robots:[]})
		this.fetchGptListByOwnerid()
	}
	

	callBack = (item) =>{
		console.log(item)
		this.setState({
			//gpt_selected:item,
			gptid_current:item.gptid,
			gptModel_current:item.model,
			gptName_current:item.gptName,
			gptHello_current:item.gptHello,
			gptDescription_current:item.gptDescription,
			gptIcon_current:item.icon,
			gptBanner_current:item.banner,
			preset_system_current:item.preset_system,
			ifPublic_current:item.ifPublic,
			ifAnyone_current:item.ifAnyone,
			ifPassword_current:item.ifPassword,
			// preset_user_current:item.preset_user,
			// preset_assistant_current:item.preset_assistant	
		
		})
		console.log(item.model)

		
		this.open_ifShowEditGpt()
	}






	handleUploadImage_afterSync = async(e,command)=> {
		

		try{
			//console.log(command)
			//console.log(this.state.gptid_current)
			e.preventDefault();	
			var url = env_serverAddress+"back/mygpt/uploadgptimage"
			let file = e.target.files[0];
			const formdata = new FormData();
			this.setState({ifUploading:true})

			// 一般上传--------------------------------------------------------
			
			var info = JSON.stringify({
				token: this.state.user_token,
				gptid:this.state.gptid_current,
				command:command
			})
			formdata.append('file', file);
			formdata.append('info', info);


			let response= await fetch(url,{
				method: 'post',
				body: formdata,
			})
			let result = await response.json()
			
			console.log(result)

			//pin1

			message.config({top: 3,duration: 2,});message.success('image uploaded!');
		}
		catch(error){
			this.setState({ifUploading:false});this.fetchGptListByOwnerid()
			this.close_ifShowEditGpt()
			message.config({top: 3,duration: 2,});message.error('image too large');
		}
		

		
	}
	handleUploadImage_banner = (e)=> {
		//this.uploadDataset()
		if(this.state.ifUploading == false){
			message.config({top: 3,duration: 2,});
			message.info('uploading, please wait');
			this.handleUploadImage_afterSync(e,"banner").then(()=>{
				this.setState({ifUploading:false});this.fetchGptListByOwnerid()
				this.close_ifShowEditGpt()
			})
		}
		else{message.config({top: 3,duration: 2,});message.info('uploading,please wait');}		
	}
	handleUploadImage_icon = (e)=> {
		//this.uploadDataset()
		if(this.state.ifUploading == false){
			message.config({top: 3,duration: 2,});
			message.info('uploading, please wait');
			this.handleUploadImage_afterSync(e,"icon").then(()=>{
				this.setState({ifUploading:false});this.fetchGptListByOwnerid()
				this.close_ifShowEditGpt()
			})
		}
		else{message.config({top: 3,duration: 2,});message.info('uploading,please wait');}		
	}





	newGpt = async() =>{

		if(this.state.list_robots.length <= 20){
			if(this.state.ifUploading == false){
				message.config({top: 3,duration: 2,});
				message.info('createing your gptbot');
				this.setState({ifUploading:true})
				var url = env_serverAddress+'back/mygpt/newgpt'
				let response= await fetch(url,{
					method: 'post',
					body: JSON.stringify({
						token:this.state.user_token,
					}), // 请求参数，JSON.stringify() JS 数据对象转换为 JSON 字符串
					headers: {
						'Content-Type': 'application/json'
					}
				})
				let data = await response.json().then()
	
				console.log(data)
				if(data == 1){
					this.fetchGptListByOwnerid()
					message.config({top: 3,duration: 2,});
					message.success('your agent was created!');
				}
				
				
				
	
		
	
			}
		}
		else{
			message.config({top: 3,duration: 2,});
			message.error('your agents reached maxium');
		}

		
	}

	createEmbeddingUrl = () =>{

		if(this.state.embeddingOriginal != ""){
			
			const startsWithHttpOrHttps = /^(http|https):\/\//.test(this.state.embeddingOriginal);
			if(startsWithHttpOrHttps){
				console.log(startsWithHttpOrHttps)
				const url = ""+domain + "?embedding="+this.state.gptToken+"&original="+this.state.embeddingOriginal
				console.log("url")
				this.setState({
					embeddingOriginal:"",
					temp_embeddingUrlCreated:url
				})
			}else{
				message.error("url invaild")
			}
			
		}else{
			message.error("Please input your url first")
		}
		
	}


	


	
	render() {
		var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		var ifMobile = width < height;
		var ifLoadingData_robots = true
		// pinReturn


		var ifPublic = false
		if(this.state.ifPublic_current == "1"){
			ifPublic = true
		}

		var ifAnyone = false
		if(this.state.ifAnyone_current == "1"){
			ifAnyone = true
		}

		var ifPassword = false

		//如果现有的密码不为零,或者正在设置密码的话(ifShowSetPassword==true)
		if(this.state.ifPassword_current != "0" || this.state.ifShowSetPassword){
			ifPassword = true
		}
		// console.log(this.state.ifPassword_current)
		// console.log(this.state.ifShowSetPassword)
		
	
		//console.log(this.state.gptModel_current)
		return(
		
			<div 
				style={{
				height:this.props.height,
				width:this.props.width,
				//backgroundColor:"#ffff00",
				overflow:"hidden",
				overflowX:"hidden"
				}}>	
				<div 
					style={{
					height:"auto",
					marginLeft:5,
					//marginTop:20,
					width:this.props.width,
					//backgroundColor:"#ffff00",
					overflowY:"hidden",
					overflowX:"hidden",
					//display: "inline-block",
			
					}}>
					{/* -------------------------------------------------- */}

					{/* <Space 
						style={{
						width:this.props.width,
						}}>
						<Compo_card_small 
							icon={"newRobot"}
							title = {"New Agent"}
							description = {"Create a new agent"}
							callBack = {this.newGpt}
							/>
					</Space>
					<div style={{height:20}}/> */}
					
					
					<div 
						style={{
						width:this.props.width,
						display: "inline-block",
						overflowX:"hidden",
						overflowY:"hidden",
						}}>
						{/* <div style={{marginLeft:20,fontSize:"15px",fontWeight:"bold"}}>
							My Agents
						</div> */}
						{/* <div style={{height:5}}/> */}
						<div style={{overflowY:"scroll",height:this.props.height-60}}>


							<GPTList 
								list_robots={this.state.list_robots}
								loading = {this.state.ifLoadingData}
								handleClick={this.callBack}
								requireGptToken={this.requireGptToken}
					 		/>
							<div style={{height:100}}/>
							{/* {this.state.ifLoadingData ? (
								<div style={{float:"left",marginTop:18,marginLeft:10,borderRadius:15,overflow:"hidden"}}>
									<Skeleton.Node active/>
								</div>
							):(
								this.state.list_robots.map(item =>{
									//var ifShowIcon = false
									return(
										<div style={{float:"left",marginTop:12,}}>
											<Compo_card_middle
												icon={item.icon}
												banner = {item.banner}
												title = {item.gptName}
												description = {item.gptDescription}
												callBack = {()=>{this.callBack(item)}}
												/>
										</div>
									)
								})
								
							)} */}
						</div>
					</div>
					{/* <Button 
						// type="text"
						style={{
							position:"absolute",left:20,bottom:20,
							width:ifMobile?(width-40):(300),
							height:50
						}} 
						onClick = {()=>{this.setState({ifShowIfLogout:true})}}
						danger>
						Logout
					</Button> */}
				</div>
				




				<Popup
					position={ifMobile? 'bottom':'right'}
					onMaskClick={() => {
						this.close_ifShowEditGpt()
						// 恢复初始设置
						this.setState({ifShowSetPassword:false,passwordVisible:false})
					  }}
					onClose={() => {
						this.close_ifShowEditGpt()
						// 恢复初始设置
						this.setState({ifShowSetPassword:false,passwordVisible:false})
					}}
					showCloseButton
					visible={this.state.ifShowEditGpt}
					bodyStyle={{
					height:ifMobile? height*0.9:height,
					width:ifMobile? width:500
					}}
					>
					<div style={{
						height:ifMobile? height*0.9:height,
						overflowX:"hidden",
						overflowY:"auto",
						width:ifMobile? width:500,
						// position:"relative"
						}}>
						{this.state.ifChangedRobotSetting? (
							<div style={{
								height:40,width:ifMobile? width:500,
								backgroundColor:"#FFDD33",
								zIndex:2,
								position:"absolute",
								bottom:0,
								right:0,
								// textAlign:"center"
								}}>
								<div
									style={{
										marginTop:13,marginLeft:40,
										// color:"#ffffff",
										// fontWeight:"bold",
									}}
									>

									<div>Save changes ? </div>	
									

									<Button 
										type="ghost"
										style={{
											height:35,position:"absolute",right:3,top:3,
											// color:"#ffffff",
											// fontWeight:"bold",
										}}
										onClick={this.updateGptSetting}
										icon={<CheckOutlined />}
										>
										Yes
									</Button>
				

								</div>
								
							</div>
						):("")}
						<div
							style={{
								height:"auto",
								overflowX:"hidden",
								overflowY: 'scroll',
								padding:20,
								//backgroundColor:"#79D760",
								width:ifMobile? width:500}}>
							<div style={{height:30}}/>
							<p>
								{/* pin1 */}
								<input 
									id = "uploadimg" type="file" accept="image/png, image/jpeg, image/jpg"
									onChange={this.handleUploadImage_banner}
									ref={this.myRef_input_uploadImage_banner}
									style={{width:this.state.width_RightBar,display:"none",}}
									/>
							</p>
							<p>
								<input 
									id = "uploadimg" type="file" accept="image/png, image/jpeg, image/jpg"
									onChange={this.handleUploadImage_icon}
									ref={this.myRef_input_uploadImage_icon}
									style={{width:this.state.width_RightBar,display:"none",}}
									/>
							</p>

							{/* <Tooltip title={"Click to upload"} placement="left"> */}
							<div style={{
								marginLeft:-20,marginTop:-65,
								width:ifMobile? width:500,
								height:(ifMobile? width:500)*0.8,
								overflow:"hidden",
								// borderRadius:15,
								backgroundColor:"#f1f1f1",
								cursor:"pointer",
								}}
								onClick={this.handleClick_pullup_input_uploadImage_banner}
								>
								{/* <Tranquiluxe 
								style={{
									width : this.state.gptBanner_current =="0" ? ifMobile? width:500:0,
									height :this.state.gptBanner_current =="0" ?  (ifMobile? width:500)*0.8 : 0
								}}/> */}
								{this.state.gptBanner_current !="0"  && (
									<ImageComponent 
										src = {this.state.gptBanner_current}
										width = {ifMobile? width:500} 
										height = {(ifMobile? width:500)*0.8} 
									/>
								)}
								{this.state.gptBanner_current =="0"  && (
									<div 
										style={{
											height:(ifMobile? width:500)*0.8,
											color:"#d8d8d8",
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											
									}}>
										<Space style={{fontSize:25}}>
											<CloudUploadOutlined />
											<div>Click to upload banner</div>

										</Space>
										
									</div>
								)}
								
								{/* <img style={{width:460}} src={this.state.gptBanner_current}/> */}
							</div>
							{/* </Tooltip> */}

							
							<div style={{height:20}} />



							<Space style={{paddingLeft:0}}>
								{/* <Tooltip title={"Click to upload"} placement="left"> */}
								<div 
									onClick={this.handleClick_pullup_input_uploadImage_icon}
									style={{
									width:60,height:60,overflow:"hidden",borderRadius:30,
									backgroundColor:"#f1f1f1"
									
									}}>


									{this.state.gptIcon_current=="0" ?(
										<Avatar
											style={{
											backgroundColor:"#000000",
											width:60,height:60,
											}}
											icon={<div style = {{marginTop:14}}><AppstoreOutlined style={{fontSize:30}}/></div>}
											/>	
									):(
										<ImageComponent 
										//src={this.state.gptIcon_current} 
										src = {this.state.gptIcon_current}
										// style={{height:60}}
										height={60}
										width={60}/>	
										
									)}
										
								</div>
								{/* </Tooltip> */}

								
								
								
								<div style={{
									height:60,width:ifMobile? width-120:380,marginLeft:7,
									//backgroundColor:"#ff0000",
									overflow:"hidden"
									}}>

									<div style={{height:7}} />
									<div style={{fontSize:18,fontWeight:"bold",overflow:"hidden"}}>
										{this.state.gptName_current}
									</div>
									<div style={{fontSize:12,paddingTop:3}}>
										{this.state.gptDescription_current}
									</div>
								</div>
							</Space>

							

							<div style={{width:ifMobile? width-40:460}}><Divider/></div>
							<div style={{
								height:70,
								// padding:20,
								width:ifMobile? width-40:460,
								// backgroundColor:"#ff0000"
								}}>
								{/* <div style={{fontSize:"23px",
									fontWeight:"bold",
									position:"absolute",left:0,top:0}}>
									Privilige
								</div> */}
								<div style={{
									height:30,
									width:"100%",
									// backgroundColor:"#00ff00",
									marginTop:5,
									position:"relative"
									}}>
									<div style={{fontSize:"17px",
										// fontWeight:"bold",
										position:"absolute",left:0,top:0}}>
									 <GlobalOutlined />{"  "}Open to Public
									</div>
									<Switch 
										style={{position:"absolute",right:0,top:0}}
										checked={ifPublic} 
										onClick={this.state.ifPublic_current=="1"?(()=>{
											console.log("11")
											this.setState({ifPublic_current:"0",ifChangedRobotSetting:true})
										}):(()=>{
											console.log("00")
											this.setState({ifPublic_current:"1",ifChangedRobotSetting:true})
										})}

										// onChange={onChange} 
									/>
								</div>

								{/* <div style={{
									height:30,
									width:"100%",
									// backgroundColor:"#00ff00",
									marginTop:5,
									position:"relative"
									}}>
									<div style={{fontSize:"17px",
										// fontWeight:"bold",
										position:"absolute",left:0,top:0}}>
										<TeamOutlined/>{"  "}Anyone can use
									</div>
									<Switch 
										style={{position:"absolute",right:0,top:0}}
										checked={ifAnyone} 
										onClick={this.state.ifAnyone_current=="1"?(()=>{
											console.log("11")
											this.setState({ifAnyone_current:"0",ifChangedRobotSetting:true})
										}):(()=>{
											console.log("00")
											this.setState({ifAnyone_current:"1",ifChangedRobotSetting:true})
										})}

										// onChange={onChange} 
									/>
								</div> */}

								<div style={{
									height:30,
									width:"100%",
									// backgroundColor:"#00ff00",
									marginTop:5,
									position:"relative"
									}}>
									<div style={{fontSize:"17px",
										// fontWeight:"bold",
										position:"absolute",left:0,top:0}}>
										<EyeOutlined />{"  "}Need password
									</div>
									<Switch 
										style={{position:"absolute",right:0,top:0}}
										checked={ifPassword} 
										onClick={this.state.ifPassword_current=="0"?(()=>{
											this.state.ifShowSetPassword? (
												//事先无密码,显示设置密码的界面
												this.setState({ifShowSetPassword:false})
											):(
												this.setState({ifShowSetPassword:true})	
											)
											
											// this.setState({ifPassword_current:"0",ifChangedRobotSetting:true})
										}):(()=>{
											//事先有密码,将密码设置为0
											this.state.ifShowSetPassword? (
												this.setState({ifShowSetPassword:false})
											):(
												this.setState({
													ifPassword_current:"0",
													ifChangedRobotSetting:true,
													ifShowSetPassword:false
												})
											)


											
										})}

										// onChange={onChange} 
									/>
								</div>

							</div>
							{this.state.ifShowSetPassword || this.state.ifPassword_current != "0"? (
								<Input
									style={{
										width:ifMobile? width-40:460
									}}
									type="text"
									maxLength={20}
									autoComplete="new-password"
									value={this.state.ifPassword_current == "0"? (""):(this.state.ifPassword_current)}
									onChange={this.onChange_password}
									visibilityToggle={{
										visible: this.state.passwordVisible,
										// onVisibleChange: this.setState({passwordVisible:!this.state.passwordVisible})
									}}
									placeholder="input password" />
									
							):("")}

							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								NAME
							</div>
							<div style={{height:10}}/>
							<Input
								placeholder='Please enter content'
								prefix={<UserOutlined/>}
								style={{
									width:ifMobile? width-40:460
								}}
								maxLength={20}
								value={this.state.gptName_current}
								onChange={this.onChange_gptName}
								// autoSize={{ minRows: 1, maxRows: 1 }}
								//ref={this.myRef}
								/>
							{/* <div style={{height:10}}/> */}
	





							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								Description
							</div>
							<div style={{height:10}}/>
				
							<TextArea
								placeholder='Please enter content'
								style={{
									width:ifMobile? width-40:460,
									// backgroundColor:"#f1f1f1",
									// borderRadius:10,
									// padding:10,
								}}
								maxLength={500}
								showCount
								value={this.state.gptDescription_current}
								onChange={this.onChange_gptDescription}
								autoSize={{ minRows: 2, maxRows: 5 }}
								//ref={this.myRef}
								/>
							{/* <div style={{height:10}}/> */}



							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								Hello
							</div>
							<div style={{height:10}}/>
				
							<TextArea
								placeholder='Please enter content'
								style={{
									width:ifMobile? width-40:460,
									// backgroundColor:"#f1f1f1",
									// borderRadius:10,
									// padding:10,
								}}
								maxLength={200}
								showCount
								value={this.state.gptHello_current}
								onChange={this.onChange_gptHello}
								autoSize={{ minRows: 2, maxRows: 5 }}
								//ref={this.myRef}
								/>
							{/* <div style={{height:10}}/> */}
							

							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								Model
							</div>
							<div style={{height:10}}/>
							{/* 							
								<Dropdown
									// style={{overflow:"scroll"}}
									//onClick = {()=>{console.log("haha")}}
									placement="top"
									menu={{
									items,
									onClick,
									}}>
									<Button 
										onClick={(e) => e.preventDefault()} 
										type='link' 
										style={{marginLeft:-10}}
										size="large"
										// icon={<UserOutlined />}
										>{window.global.gptid_clicked}</Button>
								</Dropdown> */}

							<TextArea
								placeholder='Please enter content'
								style={{
									width:ifMobile? width-40:460,
									// backgroundColor:"#f1f1f1",
									// borderRadius:10,
									// padding:10,
								}}
								value={this.state.gptModel_current }
								//value ={"gpt-3.5-turbo"}
								disabled={true}
								onChange={this.onChange_gptModel}
								autoSize={{ minRows: 1, maxRows: 1 }}
								//ref={this.myRef}
								/>
							
							{/* <Segmented  
								
								options={[
									{
										label: 'gpt-3.5',
										value: 'default',
										disabled: false,
									},
									{
										label: 'gpt-4',
										value: 'gpt-4',
										disabled: false,
									},
									{
										label: 'autoGPT',
										value: 'autoGPT',
										disabled: true,
									},
								]} 
								defaultValue = {gptModel_current}
								onChange = {this.changeModle}
								/> */}

							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								Personality
							</div>
							<div style={{height:10}}/>
				
							<TextArea
								placeholder='Please enter content'
								prefix={<UserOutlined />}
								style={{
									width:ifMobile? width-40:460,
									// backgroundColor:"#f1f1f1",
									// borderRadius:10,
									// padding:10,
								}}
								value={this.state.preset_system_current}
								onChange={this.onChange_preset_system}
								autoSize={{ minRows: 8, maxRows: 8 }}
								//ref={this.myRef}
								/>

							{/* <div style={{height:10}}/> */}
		


							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								Advance
							</div>
							<div style={{height:20}}/>
							<Button
								type="ghost"
								style={{
									width:ifMobile? width-40:460,
									height:100,backgroundColor:"#f1f1f1",
									fontWeight:"bold"}}
									onClick = {()=>{this.setState({ifShowEditNote:true})}}
								>
									<Space>
										<ImageComponent src={emoji_notebook} width={40} height={40}/>
										<div style={{width:2}}/>
										<div style={{fontSize:20}}>Notebook</div>
									</Space>
							</Button>
							
							{this.props.userData.ifPdf && (
								<div>
									<div style={{height:20}}/>
									<Button
										type="ghost"
										style={{
											width:ifMobile? width-40:460,
											height:100,backgroundColor:"#f1f1f1",
											fontWeight:"bold"}}
											onClick = {()=>{this.setState({ifShowEditReference:true})}}
										>
											<Space>
												<ImageComponent src={emoji_books} width={40} height={40}/>
												<div style={{width:2}}/>
												<div style={{fontSize:20}}>Reference</div>
											</Space>
									</Button>
								</div>
							)}

							{this.props.userData.ifPdf && (
								<div>
									<div style={{height:20}}/>
									<Button
										type="ghost"
										style={{
											width:ifMobile? width-40:460,
											height:100,backgroundColor:"#f1f1f1",
											fontWeight:"bold"}}
											onClick = {()=>{this.setState({ifShowEditSurvey:true})}}
										>
											<Space>
												<ImageComponent src={emoji_paper} width={42} height={42}/>
												<div style={{width:2}}/>
												<div style={{fontSize:20}}>Survey</div>
											</Space>
									</Button>
								</div>
							)}

							
							

							{/* <div style={{height:20}}/>
							<Button
								disabled ={true}
								type="ghost"
								style={{
									width:ifMobile? width-40:460,
									height:100,backgroundColor:"#f1f1f1",
									// color:"#ffffff",
									fontWeight:"bold",
									opacity: 0.3
									
								}}
								>
									<Space>
										<ImageComponent src={emoji_kiui} width={40} height={40}/>
										<div style={{width:2}}/>
										<div style={{fontSize:20}}>v-Memory</div>
									</Space>
							</Button> */}

							<div style={{height:20}}/>
							{/* <Button
								disabled ={true}
								type="ghost"
								style={{
									width:ifMobile? width-40:460,
									height:100,backgroundColor:"#f1f1f1",
									// color:"#ffffff",
									fontWeight:"bold",
									opacity: 0.3
									
								}}
								>
									<Space>
										<ImageComponent src={emoji_repeat} width={40} height={40}/>
										<div style={{width:2}}/>
										<div style={{fontSize:20}}>Functions</div>
									</Space>
							</Button> */}

							
				
							

							<div style={{height:20}}/>

							



						</div>
					</div>

					
				</Popup>




				{/* pinNote */}
				<Popup
					position={ifMobile? 'bottom':"right"}
					onMaskClick={() => {
						this.setState({ifShowEditNote:false})
					  }}
					onClose={() => {
						this.setState({ifShowEditNote:false})
					}}
					showCloseButton
					visible={this.state.ifShowEditNote}
					bodyStyle={{
					height:ifMobile? height*0.85:height,
					width:ifMobile? width:500
					}}
					>
					<div style={{
						height:ifMobile? height*0.85:height,
						overflowX:"hidden",
						overflowY:"auto",
						width:ifMobile? width:500,
						// position:"relative"
						}}>
						
						<div
							style={{
								height:"auto",
								overflowX:"hidden",
								overflowY: 'scroll',
								// padding:20,
								marginLeft:20,
								//backgroundColor:"#79D760",
								width:ifMobile? width-40:460}}>
							
						

							

							
							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								Notebook
							</div>
							<div style={{height:10}}/>
							{this.state.ifShowEditNote && (
								<Notebook
									gptid = {this.state.gptid_current}
									user_token = {this.state.user_token}
								/>
							)}
							
							<div style={{height:100}}/>
						</div>
					</div>
				</Popup>


				{/* pinReference */}
				<Popup
					position={ifMobile? 'bottom':"right"}
					onMaskClick={() => {
						this.setState({ifShowEditReference:false})
					  }}
					onClose={() => {
						this.setState({ifShowEditReference:false})
					}}
					showCloseButton
					visible={this.state.ifShowEditReference}
					bodyStyle={{
					height:ifMobile? height*0.85:height,
					width:ifMobile? width:500
					}}
					>
					<div style={{
						height:ifMobile? height*0.85:height,
						overflowX:"hidden",
						overflowY:"auto",
						width:ifMobile? width:500,
						// position:"relative"
						}}>
						
						<div
							style={{
								height:"auto",
								overflowX:"hidden",
								overflowY: 'scroll',
								// padding:20,
								marginLeft:20,
								//backgroundColor:"#79D760",
								width:ifMobile? width-40:460}}>
							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								Reference
							</div>
							<div style={{height:10}}/>
							<Reference
								gptid = {this.state.gptid_current}
								user_token = {this.state.user_token}
								ifShowDev = {this.props.userData.ifPdf}
								openIfShowDev = {()=>{this.setState({ifShowDev:true})}}
							/>
							<div style={{height:100}}/>
						</div>
					</div>
				</Popup>



				{/* pinUrl */}
				<Popup
					position={ifMobile? 'bottom':"right"}
					onMaskClick={() => {
						this.setState({ifShowGptUrl:false})
					  }}
					onClose={() => {
						this.setState({ifShowGptUrl:false})
					}}
					showCloseButton
					visible={this.state.ifShowGptUrl}
					bodyStyle={{
					height:ifMobile? 280:height,
					width:ifMobile? width:300
					}}
					>
					<div style={{
						height:ifMobile? 280:height,
						overflowX:"hidden",
						overflowY:"auto",
						width:ifMobile? width:300,
						// position:"relative"
						}}>
						
						<div
							style={{
								height:"auto",
								overflowX:"hidden",
								overflowY: 'scroll',
								// padding:20,
								marginLeft:20,
								//backgroundColor:"#79D760",
								width:ifMobile? width-40:260}}>
							
						

							

							
							<div style={{height:20}}/>
								<h2>Agent Url</h2>
							<div style={{height:20}}/>
							<a href={this.state.gptUrl} 
								// target="_blank" 
								rel="noopener noreferrer">
								<Button 
									block 
									// disabled={this.state.ifChangingPassword}
									// onClick ={()=>{this.resetPassword()}}
									type="primary" 
									icon={<LinkOutlined />}
									style={{height:40}}>
									Access
								</Button>
							</a>
							<div style={{height:10}}/>
							<Button 
								block 
								// disabled={this.state.ifChangingPassword}
								onClick ={()=>{
									copy(this.state.gptUrl)
									console.log(this.state.gptUrl)
									message.success('copied to clipboard!　😆');
								}}
								// type="primary" 
								icon={<CopyOutlined/>}
								style={{height:40}}>
								Copy
							</Button>
							<div style={{height:10}}/>
							<Button 
								block 
								// disabled={this.state.ifChangingPassword}
								onClick ={()=>{
									this.setState({ifShowCreateEmbedding:true})
								}}
								// type="primary" 
								icon={<CodepenOutlined/>}
								style={{height:40}}>
								Embedding
							</Button>
							
							<div style={{height:10}}/>

							



						</div>
					</div>
					

					
				</Popup>


				<Popup
					position={ifMobile? 'bottom':"right"}
					onMaskClick={() => {
						this.setState({ifShowCreateEmbedding:false})
					  }}
					onClose={() => {
						this.setState({ifShowCreateEmbedding:false})
					}}
					showCloseButton
					visible={this.state.ifShowCreateEmbedding}
					bodyStyle={{
					height:ifMobile? 350:height,
					width:ifMobile? width:300
					}}
					>
					<div style={{
						height:ifMobile? 350:height,
						overflowX:"hidden",
						overflowY:"auto",
						width:ifMobile? width:300,
						// position:"relative"
						}}>
						
						<div
							style={{
								height:"auto",
								overflowX:"hidden",
								overflowY: 'scroll',
								// padding:20,
								marginLeft:20,
								//backgroundColor:"#79D760",
								width:ifMobile? width-40:260}}>
							
						

							

							
							<div style={{height:20}}/>
								<h2>New Embedding</h2>

							<div style={{height:20}}/>
							<Input
								placeholder='https://....'
								prefix={<LinkOutlined/>}
								style={{
									width:ifMobile? width-40:300-40
								}}
								maxLength={20}
								value={this.state.embeddingOriginal}
								onChange={this.onChange_embeddingOriginal}
								// autoSize={{ minRows: 1, maxRows: 1 }}
								//ref={this.myRef}
								/>
							<div style={{height:10}}/>
							<Button 
								block 
								// disabled={this.state.ifChangingPassword}
								onClick ={()=>{
									this.createEmbeddingUrl()
								}}
								type="primary" 
								icon={<CodepenOutlined/>}
								style={{height:40}}>
								
								Create
							</Button>
							
							<div style={{height:20}}/>
							<a 
								href={this.state.temp_embeddingUrlCreated}
								rel="noopener noreferrer"
							>
								{this.state.temp_embeddingUrlCreated}
							</a>

							



						</div>
					</div>
					

					
				</Popup>


				{/* pinSurvey */}
				<Popup
					position={ifMobile? 'bottom':"right"}
					onMaskClick={() => {
						this.setState({ifShowEditSurvey:false})
					  }}
					onClose={() => {
						this.setState({ifShowEditSurvey:false})
					}}
					showCloseButton
					visible={this.state.ifShowEditSurvey}
					bodyStyle={{
					height:ifMobile? height*0.85:height,
					width:ifMobile? width:500
					}}
					>
					<div style={{
						height:ifMobile? height*0.85:height,
						overflowX:"hidden",
						overflowY:"auto",
						width:ifMobile? width:500,
						// position:"relative"
						}}>
						
						<div
							style={{
								height:"auto",
								overflowX:"hidden",
								overflowY: 'scroll',
								marginLeft:20,
								width:ifMobile? width-40:460}}>
							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								Survey
							</div>
							<div style={{height:10}}/>
							{this.state.ifShowEditSurvey && (
								<Survey
									gptid = {this.state.gptid_current}
									user_token = {this.state.user_token}
									gptIcon_current = {this.state.gptIcon_current}
									gptName_current = {this.state.gptName_current}
								/>
							)}
							
							<div style={{height:100}}/>
						</div>
					</div>
				</Popup>



				{/* pinDev */}
				<Popup
					position={ifMobile? 'bottom':"right"}
					onMaskClick={() => {
						this.setState({ifShowDev:false})
					  }}
					onClose={() => {
						this.setState({ifShowDev:false})
					}}
					showCloseButton
					visible={this.state.ifShowDev}
					bodyStyle={{
					height:ifMobile? height*0.85:height,
					width:ifMobile? width:500
					}}
					>
					<div style={{
						height:ifMobile? height*0.85:height,
						overflowX:"hidden",
						overflowY:"auto",
						width:ifMobile? width:500,
						// position:"relative"
						}}>
						
						<div
							style={{
								height:"auto",
								overflowX:"hidden",
								overflowY: 'scroll',
								marginLeft:20,
								width:ifMobile? width-40:460}}>
							<div style={{height:30}}/>
							<div style={{fontSize:"15px",fontWeight:"bold"}}>
								$Dev
							</div>
							<div style={{height:10}}/>
							{this.state.ifShowDev && (
								<DevRAG 
								width={ifMobile? width-40:460} height={200}
								userToken={this.state.user_token}
								gptid = {this.state.gptid_current}
								/>
							)}
							
							<div style={{height:100}}/>
						</div>
					</div>
				</Popup>


				<Button
					shape="circle"
					type="primary"
					style={{
						position: "fixed",
						right: 20,
						bottom: 20,
						height: 60,
						width: 60,
						fontWeight: "bold",
						boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
					}}
					onClick={() => {
						this.newGpt()
						// this.fetchPostImageTempUrls();
						// this.setState({ ifShowNewPost: true });
					}}
					icon={<PlusOutlined />}
				/>
			</div>	
		)

  	}
}

export default withRouter(App);